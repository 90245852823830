import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory, useLocation} from 'react-router-dom';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MegaMenu from '../common/MegaMenu/MegaMenu';
import { url } from '../../App';
import useAllUsers from '../../Hooks/useAllUsers';

export default function Registration() {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const [users] = useAllUsers();

   //location redirect
   const location = useLocation();
   const history = useHistory();
   const redirect_uri = location.state?.from || '/';


  const onSubmit = data => {
    let phone;

    if(data?.phone_number){
      phone = users.find(user => user.phone_number === data?.phone_number);

      //if phone_number is already exist then show alert messeage
      if(phone?.phone_number){
        alert("Phone Number is already exist!");
        reset();
      }
      
      //if phone_number isn't registered 
      else{
        //post method for register_user
        fetch(`${url}/register_user/`, {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((result) =>{
          if(result.status===400){
            alert(result.message);
          }
          else{
            localStorage.setItem('token', result.token.access);
            localStorage.setItem('user', JSON.stringify((data)));
            alert("Register Successfully!");
            reset();
            history.push(redirect_uri);
          }     
        });
      }
    }
  }

  
  return (
    <>
    <Header></Header>
    <MegaMenu></MegaMenu>
    <div className="container p-3 mb-5 row mx-auto">
      <div className="col-sm-12 col-md-12 col-lg-8 mt-3 mx-auto mb-2">
        <p className="text-center h1 fw-bold mb-4 mx-1  mt-4">সাইন আপ করুন</p>
        <form className="container mx-auto w-75 border border-0 shadow rounded p-4" onSubmit={handleSubmit(onSubmit)} >

          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">নাম:</label>
              <input
                  {...register("name", {required: true})}
                  type="text"
                  placeholder='নাম লিখুন'                  
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 custom-border-outline rounded "
              />
              {errors.name?.type === 'required' && <p role="alert" className="text-danger "><small>নাম অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ফোন নাম্বার:</label>
              <input
                  {...register("phone_number", {required: true})}
                  type="text"
                  pattern="[0-9]*"
                  placeholder='ফোন নাম্বার লিখুন'                  
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 custom-border-outline rounded "
              />
              {errors.phone_number?.type === 'required' && <p role="alert" className="text-danger "><small>ফোন নাম্বার অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">পাসওয়ার্ড:</label>
              <input
                  {...register("password", {required: true})}
                  type="password"
                  placeholder='পাসওয়ার্ড লিখুন'                  
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 custom-border-outline rounded "
              />
              {errors.password?.type === 'required' && <p role="alert" className="text-danger "><small>পাসওয়ার্ড অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className='d-flex justify-content-center mx-4 mb-3 mb-lg-4'>
              <input
                type="submit"
                value="পরবর্তী"
                className="btn  border-bg text-white rounded "
              />
            </div>
            <p className="text-center heading fw-bold">আপনার কি একাউন্ট আছে? <NavLink to={{ pathname: "/login", state: { from: location?.state?.from || '/'}}} className='heading fw-bold'>সাইন ইন করুন</NavLink>
            </p>
        </form>
      </div>
    </div>
    <Footer></Footer>
    </>
  );
}
