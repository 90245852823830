import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function useElectronicsSortByLowPrice() {
    const [sortedLowItems, setSortedItems] = useState([]);

    useEffect(() => {
        fetch(`${url}/all-product/electronics/?ordering=regular_price`)
       .then(res => res.json())
       .then(result => {
        setSortedItems(result);
       });
     }, []);

return [ sortedLowItems ];

}