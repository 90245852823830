import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import { useHistory } from 'react-router-dom';
import {url} from '../../../App';


export default function AdminPublishers({publishers}) {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const {register: searchRegister, handleSubmit: handleSearch} = useForm();
  const [ selectedImage,setSelectedImage ] = useState(null);
  const [name, setName] = useState('');
  const [searchPublishers, setSearchPublishers] = useState([]);
  const [result, setResults] = useState(0);
  const history = useHistory();


  //Publisher add function
  const onSubmit = data => {
     let formData = new FormData();
     if(selectedImage){
      formData.append('image', selectedImage);
     }
     formData.append('name', data.name);
     formData.append('description', data.description);
     formData.append('alt_text', data.alt_text);
       
     fetch(`${url}/create-publisher/`, {
       method: "POST",
       body: formData,
     })
     .then(res => {
       if (!res.ok) throw res;
       else return res.json();
     })
     .then(getData => {
       alert("Publisher added Successfully!");
       reset();
       history.push('/admin-publishers');  
     })
     .catch(err => {
       err.json().then(text => {
         if (text?.error) {
           console.log(text?.error);
           return;
         }
     })
         console.log(err.statusText);
     });
  }

  // publisher delete function
  const handleDelete = id =>{
    const confirm = window.confirm('Are you sure to delete publisher?');
    if(confirm){
        fetch(`${url}/api/publisher/${id}/`, {
           method:'DELETE'
        })
        alert('Publisher deleted!');
        window.location.reload();
    }
  }

  // searching function
  const onSearch = data =>{

    setName(data.name);
    if(data.name===''){
      setSearchPublishers([]);
      setResults(0);
    }
    else{
    fetch(`${url}/api/publisher/?search=${data.name}`)
      .then(res => res.json())
      .then(result => {
        if(result.length===0){
          setResults(1);
        }
        else{
          setResults(2);
          setSearchPublishers(result);
        }
      });
    }  
  }

  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container ">
          <AdminNavbar></AdminNavbar>
          <h3 className="update-title pt-3"> Publishers</h3>
          <hr className="update-title-hr" />
          {/* search publisher */}
          <div className="w-50 mx-auto mb-3">
            <form className='container mx-auto' onSubmit={handleSearch(onSearch)}>
              <div className="row mb-3">
                <div className='col-sm-8 col-md-9 col-lg-9 me-2 border border-success rounded-pill p-1 bg-white'>
                  <i className="fa fa-search text-center p-1"></i>
                  <input
                    {...searchRegister('name')}
                    type="text"
                    className="fs-6 p-1 w-75"
                    placeholder="Search Publisher by Name"
                  />
                </div>
                <button className='col-sm-3 col-md-2 col-lg-2 btn btn-outline-success rounded bg-white text-success fw-bold' type='submit' value='submit'>Search</button>
              </div>
            </form>
          </div>          
          {/* show search result */}
          {name===''?
            <></>
            :
            <>
              {result===0 ?
                <></>
                :
                <>
                {result===1?
                  <div className='container p-2 mb-4 bg-white table-responsive border rounded search-div '>
                    <p className='fw-bold custom-h4 p-2 fs-6'>Sorry, No result found!</p>
                  </div>
                  :
                  <>
                    <div className="container p-2 border-0 rounded shadow mb-5 bg-white table-responsive-sm">
                      <h4 className="text-center heading">Searching List</h4>
                      <hr className="w-25 mx-auto heading" />
                      <table className="mb-3 table striped bordered table-striped table-hover">
                        <thead className="custom-search">
                          <tr className="heading">
                            <th scope="col">ID</th>
                            <th scope="col" >Name</th>
                            <th scope="col">Image</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className='custom-search'>
                          {searchPublishers.map((publisher) => (
                            <tr key={publisher.id}>
                            <td>{publisher.id}</td>
                            <td>{publisher.name}</td>
                            <td>
                              <img
                                src={publisher.image}
                                height="50px"
                                width="50px"
                                alt={publisher.alt_text}
                              />
                            </td>
                            <td>
                              <NavLink to={`/update-publisher/${publisher.id}`} className='btn btn-secondary m-1' ><i className="fa-solid fa-pen-to-square text-white"></i></NavLink>
                              <button
                                onClick={() => handleDelete(publisher?.id)}
                                className="btn btn-danger m-1"
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                }
                </>
              }
            </>
          }
          {/* add publisher */}
          <div className="border-0 rounded shadow mb-3  bg-white update-form-container">
            <h4 className="text-center">Add a new Publisher</h4>
            <form className=" form " onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Name:</label>
                <input
                  {...register("name", {required: true})}
                  type="text"
                  placeholder="Enter Publisher's name"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                {errors.name?.type === 'required' && <p role="alert" className="text-danger "><small>প্রকাশকের নাম অত্যাবশ্যকিয়*</small></p>}
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Description:</label>
                <input
                  {...register("description")}
                  type="text"
                  placeholder="Enter description"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
              </div>
              <div className="row mb-3">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Image:</label>
                <input
                  {...register("image")}
                  type="file"
                  id="choose-file"
                  placeholder="Upload an Publisher image"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                  data-max-file-size="2M"
                  name="upload"
                  accept="image/*"
                  onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                  }}
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Alt-Text:</label>
                <input
                  {...register("alt_text")}
                  type="text"
                  placeholder="Alterante text"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
              </div>
              <div className='container w-50 mx-auto my-3'>
              <input
                type="submit"
                value="Submit"
                className="btn btn-success w-75 border border-success rounded"
              />
              </div>
            </form>
          </div>
          {/* view publisher list */}
          <div className="container p-2 border-0 rounded shadow mb-4 bg-white table-responsive-sm table-responsive-md ">
            <h4 className="update-title pt-3"> Publisher List</h4>
            <hr className="update-title-hr" />
            <table className="mb-3 table striped bordered table-striped table-hover ">
              <thead className="custom-tbody">
                <tr className="text-success">
                  <th scope="col">ID</th>
                  <th scope="col" className=''>Name</th>
                  <th scope="col">Image</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="custom-tbody">
                {publishers.map((publisher) => (
                  <tr key={publisher.id}>
                    <td>{publisher.id}</td>
                    <td className=''>{publisher.name}</td>
                    <td className=''>
                      <img
                        src={publisher.image}
                        height="50px"
                        width="50px"                       
                        alt={publisher.alt_text}
                      />
                    </td>
                    <td>
                      <NavLink to={`/update-publisher/${publisher.id}`} className="btn btn-secondary m-1"><i className="fa-solid fa-pen-to-square text-white"></i></NavLink>
                      <button
                        onClick={() => handleDelete(publisher?.id)}
                        className="btn btn-danger m-1"
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
