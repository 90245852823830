import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useIslamic() {
    const [islamicBooks, setIslamicBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/ইসলামিক`)
       .then(res => res.json())
       .then(result => {
        setIslamicBooks(result);
       });
    }, []);

return [ islamicBooks ];

}