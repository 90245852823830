import React, { useState, useEffect } from 'react';
import useLocalCart from '../../../Hooks/useLocalCart';
import { addToDb } from '../../../Utilities/LocalStorage';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import MegaMenu from '../../common/MegaMenu/MegaMenu';
import CategoryDetail from './CategoryDetail';
import {useParams} from 'react-router';
import useCategoryBooksSortByNew from '../../../Hooks/Sorting/SortingCategoryBooks/useCategoryBooksSortByNew';
import useCategoryBooksSortByStock from '../../../Hooks/Sorting/SortingCategoryBooks/useCategoryBooksSortByStock';
import useCategoryBooksSortByHighPrice from '../../../Hooks/Sorting/SortingCategoryBooks/useCategoryBooksSortByHighPrice';
import useCategoryBooksSortByLowPrice from '../../../Hooks/Sorting/SortingCategoryBooks/useCategoryBooksSortByLowPrice';
import Pagination from '../../Shared/Pagination/Pagination';
// import { url } from '../../../App';
import useCategoryAllBooks from '../../../Hooks/useCategoryAllBooks';

export default function CategoryDetails({authors, publishers}) {
  const {categoryName} = useParams();
  const [sorted_books, setSortedBooks] = useState([]);
  const [filteredAuthorBooks, setFilterAuthorBooks] = useState([]);
  const [filteredPublisherBooks, setFilterPublisherBooks] = useState([]);
  const [books, pageCount, handlePageClick, offset, displayBooks] = useCategoryAllBooks(categoryName);
  const [CartItem, setCartItem]= useLocalCart(books);
  const [sortedNewBooks] = useCategoryBooksSortByNew(categoryName);
  const [sortedStockBooks] = useCategoryBooksSortByStock(categoryName);
  const [sortedHighBooks] = useCategoryBooksSortByHighPrice(categoryName);
  const [sortedLowBooks] = useCategoryBooksSortByLowPrice(categoryName);
  const [sortingValue, setSortingValue] = useState('');
  const [filterAuthorValue, setFilterAuthorValue] = useState(0);
  const [filterPublisherValue, setFilterPublisherValue] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [displayTotalBooks, setDisplayTotalBooks] = useState([]);
  const [offsetBooks, setOffsetBooks] = useState(0);

  //pagination page click function
  const handleTotalPageClick=(e)=>{
    const selectedPage = e.selected;
    setOffsetBooks(selectedPage*20);
  }

  //default filter function
  const selectDefaultFilter = () =>{
    setFilterAuthorValue(0);
    setFilterPublisherValue(0);
    setSortingValue('');
  }

  //filter by author function
  const selectFilterByAuthor = (e) =>{
    setFilterAuthorValue(e.target.value);
    setFilterPublisherValue(0);
    setSortingValue('');
  }

  //filter by publisher function
  const selectFilterByPublisher = (e) =>{
    setFilterPublisherValue(e.target.value);
    setFilterAuthorValue(0);
    setSortingValue('');
  }

  //sorting function
  const selectSort = (e) =>{
    setSortingValue(e.target.value);
    setFilterPublisherValue(0);
    setFilterAuthorValue(0);
  }

  useEffect(() => {
    let data =[];

    // fetch(`${url}/category-all-book/${categoryName}/`)
    //  .then(res => res.json())
    //  .then(result => {
    //   setCategoryBooks(result);
    //   const pageNumber = (Math.floor(result.length/20))+1;
    //   setPageCount(pageNumber);
    //   setDisplayBooks(result.slice(offset, (offset+(20*1))));
    //  });
    
    if(filterAuthorValue!==0){
      data = books.filter(book => book.author == filterAuthorValue);
      setFilterAuthorBooks(data);
      setFilterPublisherBooks([]);
    }
    if(filterPublisherValue!==0){
      data = books.filter(book => book.publisher == filterPublisherValue);
      setFilterAuthorBooks([]);
      setFilterPublisherBooks(data);
    }  
    if(sortingValue === "highest"){
      data = sortedHighBooks;
      setSortedBooks(data);
    }
    if(sortingValue === "lowest"){
      data = sortedLowBooks;
      setSortedBooks(data);
    }
    if(sortingValue === "inStock"){
      data = sortedStockBooks;
      setSortedBooks(data);
    }
    if(sortingValue === "new"){
      data=sortedNewBooks;
      setSortedBooks(data);
    }
    
    const pageNumber = (Math.floor(data.length/20))+1;
    setTotalPageCount(pageNumber);
    setDisplayTotalBooks(data?.slice(offsetBooks, (offsetBooks+(20*1))));

  }, [ filterAuthorValue, filterPublisherValue, sortingValue, offsetBooks]);


  // cart function
  const handleAddToCart=(book)=>{
    const newCart=[...CartItem, book];
    setCartItem(newCart);
    addToDb(book?.id);
    alert('Successfully added!');
  }

  return (
    <>
      <Header CartItem={CartItem}></Header>
      <MegaMenu></MegaMenu>
      <div className="container mx-auto m-3 mb-5">
        <h4 className="pages-heading text-center fw-bold">{categoryName}</h4>
        <hr className="bg-success w-25 mx-auto" />
        <div className="container mx-auto m-3 row ">
          {(displayBooks?.length===0 && displayTotalBooks?.length===0) && 
            <div className='vh-100'></div>
          }
          {(displayBooks?.length!==0 || displayTotalBooks?.length!==0) && 
          <>
          <section className='filter col-lg-3 col-md-12 col-sm-12 mt-lg-5 mt-md-5'>
              <button className='text-center bg-light p-2 rounded custom-border-outline custom-text mb-3 btn mx-auto' onClick={selectDefaultFilter}>Default Filter</button>
              <select className="form-select custom-border-outline mb-2" size="5" aria-label="size 3 select example" onChange={(e)=>selectFilterByAuthor(e)}>
                <option selected className='disabled text-center fs-5 fw-bold text-white border-bg p-3'>Filter By Author's</option>
                {authors?.map((author) => (
                  <option value={author?.id} key={author?.id}  className="fs-6 fw-medium p-2 text-start text-break border-bottom">{author?.name}</option>
                ))}
              </select>
              <select className="form-select custom-border-outline" size="5" aria-label="size 3 select example" onChange={(e)=>selectFilterByPublisher(e)}>
                <option selected className='disabled text-center fs-5 fw-bold text-white border-bg p-3'>Filter By Publisher's</option>
                {publishers?.map((publisher) => (
                  <option value={publisher?.id} key={publisher?.id}  className="fs-6 fw-medium p-2 text-start text-break border-bottom ">{publisher?.name}</option>
                ))}
              </select>
            </section>
            <section className='col-lg-9 col-md-12 col-sm-12'>
              <div className="sort-filter m-3 d-flex justify-content-end">
                <div className='sort'>
                  <form action="#" className=''>
                    <label htmlFor='sort'></label>
                    <select name='sort' id='sort' className='sort-selection bg-light p-2 rounded custom-border-outline custom-text fw-medium p-2'   onChange={(e)=>selectSort(e)}>
                      <option value="default"  onClick={selectDefaultFilter}>Default Sorting</option>
                      <option value="lowest">Price low to high</option>
                      <option value="highest">Price high to low</option>
                      <option value="inStock">In stock</option>
                      <option value="new">New arrivals</option>
                    </select>
                  </form>
                </div>
              </div>
              <div className="">
                {(sortingValue==='' && filterAuthorValue===0 && filterPublisherValue===0 && displayBooks?.length!==0) &&
                <>
                  {displayBooks?.length<=4 ?
                    <div  style={{height:'1000px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayBooks?.map((book) => (
                        <CategoryDetail key={book?.id} book={book} 
                        handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayBooks?.map((book) => (
                        <CategoryDetail key={book?.id} book={book} 
                        handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={pageCount} handlePageClick={handlePageClick} offset={offset}></Pagination>
                  </div>
                </>
                }
                {(filterAuthorValue!==0 && filteredAuthorBooks?.length!==0) &&
                <>
                  {(filteredAuthorBooks?.length<=4) ?
                    <div  style={{height:'1000px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                        {displayTotalBooks?.map((book) => (
                        <CategoryDetail key={book?.id} book={book} 
                        handleAddToCart={handleAddToCart}></CategoryDetail>
                        ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks?.map((book) => (
                        <CategoryDetail key={book?.id} book={book} 
                        handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                  </div>
                </>
                }
                {(filterPublisherValue!==0 && filteredPublisherBooks?.length!==0) &&
                <>
                  {(filteredPublisherBooks?.length<=4) ?
                    <div  style={{height:'1000px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks?.map((book) => (
                        <CategoryDetail key={book?.id} book={book} 
                        handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks?.map((book) => (
                      <CategoryDetail key={book?.id} book={book} 
                      handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                  </div>
                </>
                }
                {(sortingValue!=='' && sorted_books?.length!==0) &&
                <>
                  {(sorted_books?.length<=4) ?
                    <div  style={{height:'1000px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks?.map((book) => (
                        <CategoryDetail key={book?.id} book={book} 
                        handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks?.map((book) => (
                      <CategoryDetail key={book?.id} book={book} 
                      handleAddToCart={handleAddToCart}></CategoryDetail>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                  </div>
                </>
                }
              </div>
            </section>
          </>
          }
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
