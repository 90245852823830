import React from 'react';
import { useForm } from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MegaMenu from '../common/MegaMenu/MegaMenu';
import { url } from '../../App';

export default function VerifyOTP() {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();

  //location redirect
  const history = useHistory();
  const redirect_uri = '/login';


  const onSubmit = data => {
    fetch(`${url}/verify_otp/`, {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((result) =>{
              if(result.status==400){
                alert(result.message);
              }
              else{
              alert("OTP matched Successfully!");
              }
              reset();
              history.push(redirect_uri);
        });
  }

  
  return (
    <>
    <Header></Header>
    <MegaMenu></MegaMenu>
    <div className="container p-3 mb-5 row mx-auto">
      <div className="col-sm-12 col-md-12 col-lg-8 mt-3 mx-auto mb-2">
        <p className="text-center h1 fw-bold mb-4 mx-1  mt-4">একাউন্ট যাচাই করুন</p>
        <form className="container mx-auto w-75 border border-0 shadow rounded p-4" onSubmit={handleSubmit(onSubmit)} >

          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ফোন নাম্বার:</label>
              <input
                  {...register("phone_number", {required: true})}
                  type="text"
                  pattern="[0-9]*"
                  placeholder='ফোন নাম্বার লিখুন'                  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded "
              />
              {errors.phone_number?.type === 'required' && <p role="alert" className="text-danger "><small>ফোন নাম্বার অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ও.টি.পি:</label>
              <input
                  {...register("otp", {required: true})}
                  type="text"
                  placeholder='ও.টি.পি লিখুন'                  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded "
              />
              {errors.otp?.type === 'required' && <p role="alert" className="text-danger "><small>ও.টি.পি অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className='d-flex justify-content-center mx-4 mb-3 mb-lg-4'>
              <input
                type="submit"
                value="পরবর্তী"
                className="btn btn-warning border border-warning text-white rounded "
              />
            </div>
        </form>
      </div>
    </div>
    <Footer></Footer>
    </>
  );
}
