import React from 'react';

const Head = () => {
  
  return (
    <>
      <div className="head d-none d-sm-block">
        <div className="d-flex justify-content-around  flex-lg-row ">
          <div className=" d-flex flex-lg-row flex-sm-column">
            <div className="">
              <i className="fa fa-phone"></i>
              <label>01783366160, 01771466160</label>
            </div>
            <div className="">
              <i className="fa fa-envelope"></i>
              <label>nilkhetboighor.2020@gmail.com</label>
            </div>
          </div>
          <div className=" d-flex flex-lg-row flex-sm-column">
            <div className="">
              <i className="fa-solid fa-truck-fast"></i>
              <label>ট্র্যাক মাই অর্ডার</label>
            </div>
            <div className="text-white">
              <a href='https://www.facebook.com/Nilkhetbookshop'><i className="fab fa-facebook text-white"></i>
              <label className='text-white'>ফেসবুক পেজ</label></a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Head;
