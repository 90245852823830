import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useAdmin(phone_number) {
    const [admin, setAdmin] = useState([]);

    useEffect(() => {
      if(!phone_number){
        setAdmin([]);
      }
      else{
        fetch(`${url}/api/user/?is_admin=true&phone_number=${phone_number}`)
        .then(res => res.json())
        .then(result => {
          setAdmin(result);
        });
      }
    }, [phone_number]);

return [ admin ];

}

