import React from 'react';
import './style.css';
import { Link, NavLink } from 'react-router-dom';
import defaultImage from '../../assets/images/defaultImage.jpeg';

const Authors = ({authors}) => {
  const Authors = authors.slice(0,6);

  return (
    <>
      <section className="authors background">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <i className="fas fa-user text-warning custom-icon"></i>
              <h4 className="text-center custom-h4">লেখক</h4>
            </div>
            <div className=" ">
              <Link to="/authors">
                <span>সবগুলো দেখুন</span>
              </Link>
              <i className="fa-solid fa-caret-right custom-view-icon"></i>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 g-4">
            {Authors?.map((author) => {
              return (
                <>
                  <div className="col ">
                    <div
                      className="card card-height border-1 h-100"
                      key={authors?.id}
                    >
                      {!author?.image ?
                      <div className='card-body d-flex justify-content-center align-items-center h-100'>
                        <a href={`/authors/${author.name}`}>
                          <img
                          className="authors mx-auto rounded"
                          src={defaultImage}
                          alt=""
                          />
                        </a>
                      </div>
                      :
                        <div className='card-body d-flex justify-content-center align-items-center h-100'>
                          <a href={`/authors/${author.name}`}>
                            <img
                            className="authors mx-auto"
                            src={author?.image}
                            alt={author?.alt_text}
                            />
                          </a>
                        </div>
                      }
                      <div className="card-footer bg-white border-0 h-100">
                        <NavLink className="text-white" to={`/authors/${author.name}`}>
                          <span className="card-title">{author?.name}</span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Authors;
