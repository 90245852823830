import React from 'react';
import useCategories from '../../../Hooks/useCategories';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import MegaMenu from '../../common/MegaMenu/MegaMenu';
import Pagination from '../../Shared/Pagination/Pagination';
import Category from './Category';

const Categories = () => {
    const [categories, pageCount, handlePageClick, offset, displayData] = useCategories();

    return (
        <>
        <Header ></Header>
        <MegaMenu></MegaMenu>
        <div className="container mx-auto m-3 mb-5">
          <h4 className="pages-heading text-center fw-bold">বইয়ের ক্যাটাগরী</h4>
          <hr className="bg-success w-25 mx-auto" />
          {(categories?.length===0) && <div className='vh-100'></div>}
          {categories?.length!==0 &&
          <>
            {displayData?.length<=5 ?
                <div  style={{minHeight:'1000px', maxHeight:'2500px'}}>
                  <div className="g-4 row row-cols-1 row-cols-md-4 row-cols-lg-5 container mx-auto">
                    {displayData.map((category) => (
                    <Category key={category?.id} category={category}></Category>
                    ))}
                  </div>
                </div>
                :
                <div className="g-4 row row-cols-1 row-cols-md-4 row-cols-lg-5 container mx-auto">
                {displayData.map((category) => (
                  <Category key={category?.id} category={category}></Category>
                ))}
                </div>
            }
            <div className='container w-75 mx-auto mt-5'>              
              <Pagination pageCount={pageCount} handlePageClick={handlePageClick} offset={offset}></Pagination>
            </div>
          </>
          }
        </div>
        <Footer></Footer>
      </>
    );
}

export default Categories;