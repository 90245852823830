import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useAllCategories() {
    const [categories, setCategories] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [displayData, setDisplayData] = useState([]);
    const [offset, setOffset] = useState(0);
    
    const handlePageClick=(e)=>{
      const selectedPage = e.selected;
      setOffset(selectedPage*20);
    }

    useEffect(() => {
        fetch(`${url}/api/category/`)
       .then(res => res.json())
       .then(result => {
         setCategories(result);
         const pageNumber = (Math.floor(result.length/20))+1;
         setPageCount(pageNumber);
         setDisplayData(result.slice(offset, (offset+(20*1))));
       });
    },[offset]);   

return [ categories, pageCount, handlePageClick, offset, displayData];
}
