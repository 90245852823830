import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useCategoryByID(id) {
    const [singleCategory, setSingleCategory] = useState([]);
    const [category, setCategory] = useState('');
    
    useEffect(() => {
      if(!id){
        setSingleCategory([]);
        setCategory('');      
      }
      else{
        fetch(`${url}/api/category/${id}`)
       .then(res => res.json())
       .then(result => {
         setSingleCategory(result);
         setCategory(result.name);
       });
      }
    }, [id]);

  return [ singleCategory];

}