import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container mx-auto">
          <div className=" footer ">
            <div className="me-4">
              <div className="d-flex  ">
                <img
                  height="30px"
                  width="30px"
                  src="../../images/favicon/book-logo.png"
                  alt=""
                />
                <div>
                  <h3 className="text-warning ps-2 mb-1">Nilkhet Boighor</h3>
                  <hr width="260px" className="text-white " />
                </div>
              </div>
              <p className="pt-2">
                NBG is the fastest online Books Delivery site. <br />
                It also works with stationary and electronics items.
                <br />
                It Delivers all over the country.
              </p>
              <p>Follow us on <a href='https://www.facebook.com/Nilkhetbookshop' className='fw-bold text-white'><i className="fa-brands fa-facebook px-2 fs-4"></i></a></p>
            </div>

            <div className="me-4">
              <h4>Supports</h4>
              <hr width="100px" className="text-white " />
              <p className="fw-bold">Order Track</p>
              <p className="fw-bold">Pre Order</p>
              <p className="fw-bold">Complain</p>
              <p className="fw-bold">Contact Us</p>
              <p className="fw-bold">Policy</p>
              <NavLink to='/login' className='text-white'><p className="fw-bold">Sign in</p></NavLink>
              <p className="fw-bold">Terms & Conditions</p>
            </div>
            <div className="me-4">
              <h4>Shop By</h4>
              <hr width="100px" className="text-white " />
              <NavLink to='/books' className='text-white'><p className="fw-bold">Book</p></NavLink>
              <NavLink to='/electronics' className='text-white'><p className="fw-bold">Electronics</p></NavLink>
              <NavLink to='/stationary' className='text-white'><p className="fw-bold">Stationary</p></NavLink>
              <p className="fw-bold">Printing Service </p>
              <p className="fw-bold">Custom Order </p>
            </div>
            <div >
              <h4 className='me-3'>Contact Us</h4>
              <hr width="130px" className="text-white " />

              <p className="fw-bold"><b>Address:</b> 5, Shahi Moriom Bibi Mosjid Market <br /> Nilkhet, Dhaka-1205</p>
              <p className="fw-bold"><b>Email:</b> nilkhetboighor.2020@gamil.com</p>
              <p className="fw-bold"><b>Phone:</b> 01783366160, 01771466160</p>
              <p className="fw-bold fs-5">
                <img
                  width="100px"
                  height="30px"
                  src="../../../images/footer/bkash-nagad-rocket-1.png"
                  alt="Bkash Nagad Rocket"
                />
                : 01736964867
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;