import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useCategories() {
  const [categories, setCategories] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [displayData, setDisplayData] = useState([]);
  const [offset, setOffset] = useState(0);
  
  const handlePageClick=(e)=>{
    const selectedPage = e.selected;
    setOffset(selectedPage*20);
  }

  useEffect(() => {
    fetch(`${url}/api/category/?name=বই`)
      .then(res => res.json())
      .then(result => {
        setCategories(result[0].children);
        const pageNumber = (Math.floor(result[0].children.length/20))+1;
        setPageCount(pageNumber);
        setDisplayData(result[0].children.slice(offset, (offset+(20*1))));
      });
  }, [offset]);

  return [ categories, pageCount, handlePageClick, offset, displayData];
}