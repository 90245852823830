import React, {useState, useEffect} from 'react';
import useBooksSortByHighPrice from '../../../Hooks/Sorting/useBooksSortByHighPrice';
import useBooksSortByLowPrice from '../../../Hooks/Sorting/useBooksSortByLowPrice';
import useBooksSortByNew from '../../../Hooks/Sorting/useBooksSortByNew';
import useBooksSortByStock from '../../../Hooks/Sorting/useBooksSortByStock';
import useLocalCart from '../../../Hooks/useLocalCart';
import { addToDb } from '../../../Utilities/LocalStorage';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import MegaMenu from '../../common/MegaMenu/MegaMenu';
import Book from './Book';
import '../Books.css';
import Pagination from '../../Shared/Pagination/Pagination';

export default function Books({books, pageCount, handlePageClick, offset, displayBooks, authors, publishers}) { 
  const [sorted_books, setSortedBooks] = useState([]);
  const [filteredAuthorBooks, setFilterAuthorBooks] = useState([]);
  const [filteredPublisherBooks, setFilterPublisherBooks] = useState([]);
  const [sortedNewBooks] = useBooksSortByNew();
  const [sortedStockBooks] = useBooksSortByStock();
  const [sortedLowBooks] = useBooksSortByLowPrice();
  const [sortedHighBooks] = useBooksSortByHighPrice();
  const [sortingValue, setSortingValue] = useState('');
  const [filterAuthorValue, setFilterAuthorValue] = useState(0);
  const [filterPublisherValue, setFilterPublisherValue] = useState(0);
  const [CartItem, setCartItem]= useLocalCart(books);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [displayTotalBooks, setDisplayTotalBooks] = useState([]);
  const [offsetBooks, setOffsetBooks] = useState(0);

  //pagination page click function
  const handleTotalPageClick=(e)=>{
    const selectedPage = e.selected;
    setOffsetBooks(selectedPage*20);
  }

  //default filter function
  const selectDefaultFilter = () =>{
    setFilterAuthorValue(0);
    setFilterPublisherValue(0);
    setSortingValue('');
  }

  //filter by author function
  const selectFilterByAuthor = (e) =>{
    setFilterAuthorValue(e.target.value);
    setFilterPublisherValue(0);
    setSortingValue('');
  }

  //filter by publisher function
  const selectFilterByPublisher = (e) =>{
    setFilterPublisherValue(e.target.value);
    setFilterAuthorValue(0);
    setSortingValue('');
  }

  //sorting function
  const selectSort = (e) =>{
    setSortingValue(e.target.value);
    setFilterPublisherValue(0);
    setFilterAuthorValue(0);
  }

  useEffect(() => {
    let data =[];

    if(filterAuthorValue!==0){
      data = books.filter(book => book.author == filterAuthorValue);
      setFilterAuthorBooks(data);
      setFilterPublisherBooks([]);
    }

    if(filterPublisherValue!==0){
      data = books.filter(book => book.publisher == filterPublisherValue);
      setFilterAuthorBooks([]);
      setFilterPublisherBooks(data);
    }  

    if(sortingValue === "highest"){
      data = sortedHighBooks;
      setSortedBooks(data);
    }
    if(sortingValue === "lowest"){
      data = sortedLowBooks;
      setSortedBooks(data);
  
    }
    if(sortingValue === "inStock"){
      data = sortedStockBooks;
      setSortedBooks(data);
  
    }
    if(sortingValue === "new"){
      data=sortedNewBooks;
      setSortedBooks(data);
    }
    
    const pageNumber = (Math.floor(data.length/20))+1;
    setTotalPageCount(pageNumber);
    setDisplayTotalBooks(data.slice(offsetBooks, (offsetBooks+(20*1))));

  }, [books, filterAuthorValue, filterPublisherValue, sortingValue, offsetBooks]);



  // handleAddToCart function
  const handleAddToCart=(book)=>{
    const newCart=[...CartItem, book];
    setCartItem(newCart);
    addToDb(book.id);
    alert('Book addded successfully!');
  }

  return (
    <>
      <Header CartItem={CartItem}></Header>
      <MegaMenu></MegaMenu>
      <h4 className="pages-heading text-center fw-bold">Our Books</h4>
      <hr className="bg-success w-25 mx-auto" />
      <div className="container mx-auto m-3 row mb-5">
        {(displayBooks.length===0 && displayTotalBooks.length===0) && 
        <div className='vh-100'></div>
        }
        {(displayBooks.length!==0 || displayTotalBooks.length!==0) && 
        <>
          <section className='filter col-lg-3 col-md-12 col-sm-12 mt-lg-5 mt-md-5'>
            <button className='text-center bg-light p-2 rounded custom-border-outline custom-text mb-3 btn mx-auto' onClick={selectDefaultFilter}>Default Filter</button>
            <div className='row row-cols-lg-1 row-cols-md-2 row-cols-1'>
              <div className='col'>
                <select className="form-select custom-border-outline mb-3" size="5" aria-label="size 3 select example" onChange={(e)=>selectFilterByAuthor(e)}>
                  <option selected disabled className=' text-center fs-5 fw-bold text-white border-bg p-3 mb-1'>Filter By Author's</option>
                  {authors.map((author) => (
                    <option value={author.id} key={author.id}  className="fs-6 fw-medium p-2 text-start text-break custom-text border-bottom" data-bs-toggle="tooltip" data-bs-placement="bottom" title={author.name}>{author.name}</option>
                  ))}
                </select>
              </div>
              <div className='col'>
                <select className="form-select custom-border-outline" size="5" aria-label="size 3 select example" onChange={(e)=>selectFilterByPublisher(e)}>
                  <option selected disabled className='text-center fs-5 fw-bold text-white border-bg p-3 mb-1'>Filter By Publisher's</option>
                  {publishers.map((publisher) => (
                    <option value={publisher.id} key={publisher.id}  className="fs-6 fw-medium p-2 text-start text-break custom-text border-bottom" data-bs-toggle="tooltip" data-bs-placement="bottom" title={publisher.name}>{publisher.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </section>
          <section className='col-lg-9 col-md-12 col-sm-12 mt-lg-5 mt-md-5'>
            <div className="sort-filter m-3 d-flex justify-content-end">
              <div className='sort'>
                <form action="#" className=''>
                  <label htmlFor='sort'></label>
                  <select name='sort' id='sort' className='sort-selection bg-light p-2 rounded custom-border-outline custom-text fw-medium p-2'  onChange={(e)=>selectSort(e)}>
                      <option value="default" className='custom-text fw-medium'  onClick={selectDefaultFilter}>Default Sorting</option>
                      <option value="lowest" className='custom-text fw-medium'>Price low to high</option>
                      <option value="highest" className='custom-text fw-medium'>Price high to low</option>
                      <option value="inStock" className='custom-text fw-medium'>In stock</option>
                      <option value="new" className='custom-text fw-medium'>New arrivals</option>
                  </select>
                </form>
              </div>
            </div>
            <div className="">
              {(sortingValue==='' && filterAuthorValue===0 && filterPublisherValue===0 && displayBooks?.length!==0) &&
              <>
                {displayBooks?.length<=4 ?
                  <div style={{minHeight:'1000px', maxHeight:'2500px'}}>
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                    {displayBooks.map((book) => (
                    <Book key={book.id} book={book} 
                    handleAddToCart={handleAddToCart}></Book>
                    ))}
                    </div>
                  </div>
                :
                  <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                    {displayBooks.map((book) => (
                    <Book key={book.id} book={book} 
                    handleAddToCart={handleAddToCart}></Book>
                    ))}
                  </div>
                }
                <div className='container w-75 mx-auto mt-5'>              
                  <Pagination pageCount={pageCount} handlePageClick={handlePageClick} offset={offset}></Pagination>
                </div>
              </>
              }
              {(filterAuthorValue!==0 && filteredAuthorBooks?.length!==0) &&
                <>
                  {(filteredAuthorBooks?.length<=4) ?
                    <div style={{minHeight:'1000px', maxHeight:'2500px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks.map((book) => (
                      <Book key={book.id} book={book} handleAddToCart={handleAddToCart}></Book>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks.map((book) => (
                      <Book key={book.id} book={book} handleAddToCart={handleAddToCart}></Book>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                  </div>
                </>
                }
                {(filterPublisherValue!==0 && filteredPublisherBooks?.length!==0) &&
                <>
                  {(filteredPublisherBooks?.length<=4) ?
                    <div style={{minHeight:'1000px', maxHeight:'2500px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks.map((book) => (
                      <Book key={book.id} book={book} handleAddToCart={handleAddToCart}></Book>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks.map((book) => (
                      <Book key={book.id} book={book} handleAddToCart={handleAddToCart}></Book>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                  </div>
                </>
                }
                {(sortingValue!=='' && sorted_books?.length!==0) &&
                <>
                  {(sorted_books?.length<=4) ?
                    <div style={{minHeight:'1000px', maxHeight:'2500px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks.map((book) => (
                      <Book key={book.id} book={book} handleAddToCart={handleAddToCart}></Book>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalBooks.map((book) => (
                      <Book key={book.id} book={book} handleAddToCart={handleAddToCart}></Book>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                  </div>
                </>
                }
            </div>
          </section>
        </>
        }
      </div>
      <Footer></Footer>
    </>
  );
}
