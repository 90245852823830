import React from 'react';
import './CompetitiveExam.css';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, Navigation} from 'swiper';

const CompetitiveExam = ({competitiveBooks, handleAddToCart, handleAddToWishlist}) => {

  return (
    <>
      <section className="competitive-exam bg-light">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <i className="fas fa-book-open text-warning custom-icon"></i>
              <h4 className="text-center custom-h4">কম্পিটিটিভ-এক্সাম</h4>
            </div>
            <div className=" ">
            <NavLink className=" " to={`/category/কম্পিটিটিভ-এক্সাম`}>
              <span className='text-decoration-none viewall-btn'>সবগুলো দেখুন</span>
              <i className="fa-solid fa-caret-right custom-view-icon"></i>
            </NavLink>
            </div>
          </div>
          <div className="mx-auto">
            <Swiper
              slidesPerView={6}
              grabCursor={true}
              loop={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[ Autoplay, Navigation]}
              breakpoints= {{
                360: {
                  slidesPerView: 1
                },
                375: {
                  slidesPerView: 1
                },
                380: {
                  slidesPerView: 1
                },
                400:{
                  slidesPerView:1
                },
                412: {
                  slidesPerView: 1
                },
                414: {
                  slidesPerView: 1
                },
                428: {
                  slidesPerView: 1
                },
                640: {
                  slidesPerView: 2
                },
                768: {
                  slidesPerView: 3
                },
                1024: {
                  slidesPerView: 6
                }
              }}
              className="mySwiper"
              >
              {competitiveBooks?.map((book) => {
                return (
                  <>
                    <SwiperSlide
                      className="border-1 mx-lg-1 mx-md-1"
                      key={book.id}
                    >
                      <div className=" h-100 card">
                        <div className="">
                        {book?.percentage!==0 && <span className="discount">{book?.percentage}% ছাড়</span>}
                        {book?.flat_discount!==0 && <span className="discount">{book?.flat_discount} টাকা ছাড়</span>}
                        <a href={`/books/${book.id}`}>
                          <img
                            className=" slide-img "
                            src={book?.image}
                            alt={book?.alt_text}
                          />
                        </a>
                        <div className="product-like">
                          <label>
                            <button
                            id='wishlistbtn'
                              className=" px-2 rounded-circle custom-wishlist "
                              onClick={() => handleAddToWishlist(book)}
                            >
                              <i className="fa-regular fa-heart text-white "></i>
                            </button>
                          </label>
                          <br />
                        </div>
                        </div>
                        <div className="card-body h-100 text-start">
                          <NavLink className="text-white" to={`/books/${book.id}`}>
                            <span className="card-title">{book?.english_title.slice(0,15)}...</span>
                          </NavLink>
                          <div>
                            {(book?.discount_price===0 || book?.discount_price===null) ?  <h6 className="card-text text-start text-dark">{book?.regular_price} টাকা</h6>
                            :
                            <div className="d-inline-flex ">
                              <div className="me-2">
                                <h6 className="card-text text-start text-dark text-decoration-line-through">{book?.regular_price} টাকা</h6>
                              </div>
                              <div className="ms-2">
                                <h6 className="card-text text-start text-danger fw-bold">{book?.discount_price} টাকা</h6>
                              </div>
                            </div>
                            } 
                          </div>
                        </div>
                        <div className='card-footer bg-white border-0 col-12'>
                          {book?.units_stock!==0 ?
                          <button className="btn col-12 rounded addtocart" onClick={() => handleAddToCart(book)}>
                            <span className='text-white fs-6'>Add to cart <i className="fa fa-plus"></i></span>
                          </button>
                          :
                          <button className="btn col-12 rounded addtocart disabled">
                              <span className='text-white fs-6'>Stock out!</span>
                          </button>
                          } 
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                );
              })}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};
export default CompetitiveExam;
