import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import { useForm } from 'react-hook-form';
import '../AdminDashboard.css';
import {url} from '../../../App';
import Select from 'react-select';
import useCategories from '../../../Hooks/useCategories';


export default function AdminBooks({books, authors, publishers}) {
  let categoryList = [];
  let authorList = [];
  let publisherList = [];
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const {register: searchRegister, handleSubmit: handleSearch} = useForm();
  const [categories] = useCategories([]);
  const [title, setTitle] = useState('');
  const [searchProducts, setSearchProducts] = useState([]);
  const [result, setResults] = useState(0);
  const [ selectedImage,setSelectedImage ] = useState(null);
  const [ selectedPDF,setSelectedPDF ] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [category, setCategory] = useState('');
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [author, setAuthor] = useState('');
  const [selectedPublisher, setSelectedPublisher] = useState(null);
  const [publisher, setPublisher] = useState('');
  const [offerType, setOfferType] = useState('');

  //category select
  Array.from(categories).forEach((category) => {
    (category?.children) &&
    <>
      {category?.children.map((child) => (
        categoryList.push({ label:`${category?.name} -> ${child?.name}`, value: child.id})
      ))}
    </>
  })

  const categoryHandleChange = (e) => {
    setSelectedCategory(e.value);
    setCategory(e);
  } 

  //author select
  Array.from(authors).forEach((author) => {
    authorList.push({ label:`${author?.name}`, value: author.id});
  })

  const authorHandleChange = (e) => {
    setSelectedAuthor(e.value);
    setAuthor(e);
  }

  //publisher select
  Array.from(publishers).forEach((publisher) => {
    publisherList.push({ label:`${publisher?.name}`, value: publisher.id});
  })

  const publisherHandleChange = (e) => {
    setSelectedPublisher(e.value);
    setPublisher(e);
  }

  //offer_type select
  const selectOfferType = (e) =>{
    setOfferType(e.target.value);
  }

  //book add function
  const onSubmit = data => {
     let formData = new FormData();
     if(!category){
      alert('Category field is required!');
     }
     if(!author){
      alert('Author field is required!');
     }
     if(!publisher){
      alert('Publisher field is required!');
     }
     if(data.percentage){
      let percentage_value = data.regular_price*(data.percentage/100);
      data.discount_price = parseInt(data.regular_price-percentage_value);
      data.flat_discount = 0;
     }
     if(data.flat_discount){
      data.discount_price = parseInt(data.regular_price-data.flat_discount);
      data.percentage = 0;
     }
     if(data.offer_type===''){
      data.discount_price=0;
      data.percentage = 0;
      data.flat_discount = 0;
     }
     if(data.units_sold===''){
      data.units_sold=0;
      formData.append('units_sold', data.units_sold);
     }
     if(data.description===''){
      data.description = '';
     }
     if(data.total_page){
      formData.append('total_page', data.total_page);
     }
     

     formData.append('english_title', data.english_title);
     formData.append('bangla_title', data.bangla_title);
     formData.append('description', data.description);
     formData.append('category', category);
     formData.append('type', 'books');
     formData.append('author', author);
     formData.append('publisher', publisher);
     formData.append('isbn', data.isbn);
     formData.append('edition', data.edition);
     formData.append('language', data.language);
     formData.append('country', data.country);
     formData.append('offer_type', data.offer_type);
     formData.append('percentage', data.percentage);
     formData.append('flat_discount', data.flat_discount);
     formData.append('purchase_from', data.purchase_from);
     formData.append('purchase_price', data.purchase_price);
     formData.append('regular_price', data.regular_price);
     formData.append('discount_price', data.discount_price);
     formData.append('units_stock', data.units_stock);
     formData.append('is_active', 'true');
     formData.append('image', selectedImage);
     formData.append('alt_text', data.alt_text);
     formData.append('pdf', selectedPDF);
    
     fetch(`${url}/create-book/`, {
       method: "POST",
       body: formData,
     })
     .then(res => {
       if (!res.ok) throw res;
       else return res.json();
     })
     .then(getData => {
       alert("Book added Successfully!");
       reset();
       window.location.reload();
     })
     .catch(err => {
       err.json().then(text => {
         if (text?.error) {
           console.log(text?.error);
           return;
         }
     })
         console.log(err.statusText);
     });
  }

  // book delete function
  const handleDelete = id =>{
    const confirm = window.confirm('Are you sure to delete book?');
    if(confirm){
        fetch(`${url}/api/product/${id}/`, {
           method:'DELETE'
        })
        .then(res=>res.json())
    }
    alert('Book deleted!');
    window.location.reload();
  }

  // searching function
  const onSearch = data =>{

    setTitle(data.title);
    if(data.title===''){
      setSearchProducts([]);
      setResults(0);
    }
    else{
      fetch(`${url}/all-product/books/?search=${data.title}`)
        .then(res => res.json())
        .then(result => {
          if(result.length===0){
            setResults(1);
          }
          else{
            setResults(2);
            setSearchProducts(result);
          }
      });
    }  
  }

//   //demo add function
//   const onDemoSubmit = data => {
//     let formData = new FormData();

//     for(let i=0; i<selectedGalleryImage.length; i++){
//       formData.append(`image[${i}]`, selectedGalleryImage[i]);
//     }

//     formData.append('name', data.name);
   
//     fetch(`${url}/demoProduct/`, {
//       method: "POST",
//       body: formData,
//     })
//     .then(res => {
//       if (!res.ok) throw res;
//       else return res.json();
//     })
//     .then(getData => {
//       alert("demo added Successfully!");
//       demoReset();
//       window.location.reload();
//     })
//     .catch(err => {
//       err.json().then(text => {
//         if (text?.error) {
//           console.log(text?.error);
//           return;
//         }
//     })
//         console.log(err.statusText);
//     });
//  }

  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container ">
          <AdminNavbar></AdminNavbar>
          <h3 className="text-center pt-3">Books</h3>
          <hr className="w-25 mx-auto" />
          {/* search product */}
          <div className="w-50 mx-auto mb-3">
            <form className='container mx-auto' onSubmit={handleSearch(onSearch)}>
              <div className="row mb-3">
                <div className='col-sm-8 col-md-9 col-lg-9 me-2 border border-success rounded-pill p-1 bg-white'>
                  <i className="fa fa-search text-center p-1"></i>
                  <input
                      {...searchRegister('title')}
                      type="text"
                      className="fs-6 p-1 w-75"
                      placeholder="Search product by title, author, publisher, category"
                  />
                </div>
                <button className='col-sm-3 col-md-2 col-lg-2 btn btn-outline-success rounded bg-white text-success fw-bold' type='submit' value='submit'>Search</button>
              </div>
            </form>
          </div>

          {/* show search result */}
          {title===''?
            <></>
            :
            <>
              {result===0 ?
                <></>
                :
                <>
                {result===1?
                  <div className='container p-2 mb-4 bg-white table-responsive border rounded search-div '>
                    <p className='fw-bold custom-h4 p-2 fs-6'>Sorry, No result found!</p>
                  </div>
                  :
                  <>
                    <div className="container p-2 border-0 rounded shadow mb-5 bg-white table-responsive-sm">
                      <h4 className="text-center heading">Searching List</h4>
                      <hr className="w-25 mx-auto heading" />
                      <table className="mb-3 table striped bordered table-striped table-hover">
                        <thead className="custom-search">
                          <tr className="text-start">
                            <th scope="col">ID</th>
                            <th scope="col">English Title</th>
                            <th scope="col">Bangla Title</th>
                            <th scope="col">Category</th>
                            <th scope="col">Image</th>
                            <th scope="col">Regular Price</th>
                            <th scope="col">Discount Price</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Sold</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className='custom-search'>
                          {searchProducts.map((book) => (
                            <tr key={book.id}>
                              <td className='text-start'>{book?.id}</td>
                              <td className='text-start'>{book?.english_title}</td>
                              <td className='text-start'>{book?.bangla_title}</td> 
                              <td className='text-start'>{book?.category}</td>
                              <td>
                                <img src={book?.image} height="50px" width="50px" alt={book?.alt_text} />
                              </td>
                              <td>{book?.regular_price}</td>
                              <td>{book?.discount_price}</td>
                              <td>{book?.units_stock}</td>
                              <td>{book?.units_sold}</td>
                              <td>
                                <NavLink to={`/update-book/${book.id}`} className="btn btn-secondary m-1">
                                <i className="fa-solid fa-pen-to-square text-white"></i>
                                </NavLink>
                                <button
                                  onClick={() => handleDelete(book?.id)}
                                  className="btn btn-danger m-1"
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                }
                </>
              }
            </>
          }

          {/* add a new product */}
          <div className="container py-3 border-0 rounded shadow mx-auto w-75 mb-3 bg-white">
            <h4 className="text-center pb-3">Add a new book</h4>
            <form className="container "  onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Book English Title:</label>
                <input
                  {...register("english_title", {required: true})}
                  type="text"
                  placeholder="Enter book's english title"
                  className=" p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                {errors.english_title?.type === 'required' && <p role="alert" className="text-danger "><small>Book english title is required*</small></p>}
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Book Bangla Title:</label>
                <input
                  {...register("bangla_title")}
                  type="text"
                  placeholder="Enter book's bangla title"
                  className=" p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Category:</label>
                <Select 
                  value={selectedCategory}
                  options={categoryList} placeholder="একটি ক্যাটাগরী
                  নির্বাচন করুন"
                  onChange={(e)=>categoryHandleChange(e.value)}
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded "
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Description:</label>
                <textarea
                  {...register("description")}
                  type="text"
                  placeholder="description"
                  className="p-2 col-sm-12 col-md-10 col-lg-10 border border-success rounded"
                  cols="60" rows="10"
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Author:</label>
                <Select 
                  value={selectedAuthor}
                  options={authorList} placeholder="একটি লেখক
                  নির্বাচন করুন"
                  onChange={(e)=>authorHandleChange(e.value)}
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded "
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Publisher:</label>
                <Select 
                  value={selectedPublisher}
                  options={publisherList} placeholder="একটি প্রকাশক
                  নির্বাচন করুন"
                  onChange={(e)=>publisherHandleChange(e.value)}
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded "
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Upload image:</label>
                <input
                  {...register("image", {required: true})}
                  type="file"
                  id="choose-file"
                  placeholder="Upload an Book image"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                  data-max-file-size="2M"
                  name="upload"
                  accept="image/*"
                  onChange={(event) => {
                  setSelectedImage(event.target.files[0])
                  }}
                />
                {errors.image?.type === 'required' && <p role="alert" className="text-danger "><small>Books image is required*</small></p>}
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Alt-text:</label>
                <input
                {...register("alt_text")}
                type="text"
                placeholder="alternate text"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">ISBN:</label>
                <input
                  {...register("isbn")}
                  type="text"
                  placeholder="isbn no."
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Edition:</label>
                <input
                  {...register("edition")}
                  type="text"
                  placeholder="edition"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Language:</label>
                <input
                  {...register("language")}
                  type="text"
                  placeholder="language"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Country:</label>
                <input
                  {...register("country")}
                  type="text"
                  placeholder="country"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Total page:</label>
                <input
                {...register("total_page")}
                type="number"
                min="0"
                placeholder="total_page"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">PDF:</label>
                <input
                  {...register("pdf")}
                  type="file"
                  id="choose-file"
                  placeholder="Upload an Book PDF"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                  name="upload"
                  accept=".pdf"
                  onChange={(event) => {
                  setSelectedPDF(event.target.files[0]);
                  }}
                />
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Purchase from:</label>
                <textarea
                {...register("purchase_from")}
                type="text"
                placeholder="purchase from"
                className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                cols="20" rows="4"
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Purchase price:</label>
                <input
                {...register("purchase_price", {required: true})}
                type="number"
                min="0"
                placeholder="purchase price"
                className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                {errors.regular_price?.type === 'required' && <p role="alert" className="text-danger "><small>Purchase Price is required*</small></p>}
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Offer type:</label>
                <select className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded" {...register("offer_type")}  onChange={(e)=>selectOfferType(e)}>
                  <option value='' selected>একটি অফার টাইপ
                  নির্বাচন করুন</option>
                  <option value='Percentage'>Percentage</option>
                  <option value='Flat Discount'>Flat Discount</option>
                </select>
                {(offerType==='Percentage') && 
                <>
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Percentage:</label>
                <input
                  {...register("percentage")}
                  type="number"
                  min="0"
                  placeholder="Percentage value"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                </>
                }
                {(offerType==='Flat Discount') && 
                <>
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Flat Discount:</label>
                <input
                  {...register("flat_discount")}
                  type="number"
                  min="0"
                  placeholder="Flat Discount value"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                </>
                }
              </div>
              <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Regular price:</label>
                <input
                {...register("regular_price", {required: true})}
                type="number"
                min="0"
                placeholder="regular price"
                className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                {errors.regular_price?.type === 'required' && <p role="alert" className="text-danger "><small>Regular Price is required*</small></p>}
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">*Stock:</label>
                <input
                {...register("units_stock", {required: true})}
                type="number"
                min="0"
                placeholder="stock in hand"
                className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                {errors.units_stock?.type === 'required' && <p role="alert" className="text-danger "><small>Units of Stock is required*</small></p>}
              </div>
              <div className='container w-50 mx-auto my-3'>
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-success w-75 border border-success rounded"
                />
              </div>
            </form>
          </div>

          {/* show book list */}
          <div className="container w-100 mx-auto mb-4">
            <h4 className="text-center"> Book List</h4>
            <hr className="w-25 mx-auto" />
            <div className=" bg-white table-responsive p-3">
              <table className="mb-3 table table-striped table-hover mx-auto ">
                <thead className="custom-tbody">
                  <tr className="text-success text-start">
                    <th scope="col">ID</th>
                    <th scope="col">English Title</th>
                    <th scope="col">Bangla Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">Image</th>
                    <th scope="col">Purchase From</th>
                    <th scope="col">Purchase Price</th>
                    <th scope="col">Regular Price</th>
                    <th scope="col">Discount Price</th>
                    <th scope="col">Stock</th>
                    <th scope="col">Sold</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className='custom-tbody'>
                  {books?.map((book) => (
                    <tr key={book?.id}>
                      <td>{book?.id}</td>
                      <td className='text-start'>{book?.english_title}</td>
                      <td className='text-start'>{book?.bangla_title}</td> 
                      <td className='text-start'>{book?.category}</td>
                      <td>
                        <img src={book?.image} height="50px" width="50px" alt={book?.alt_text} />
                      </td>
                      <td>{book?.purchase_from}</td>
                      <td>{book?.purchase_price}</td>
                      <td>{book?.regular_price}</td>
                      <td>{book?.discount_price}</td>
                      <td>{book?.units_stock}</td>
                      <td>{book?.units_sold}</td>
                      <td>
                        <NavLink to={`/update-book/${book.id}`} className="btn btn-secondary m-1">
                        <i className="fa-solid fa-pen-to-square text-white"></i>
                        </NavLink>
                        <button
                          onClick={() => handleDelete(book?.id)}
                          className="btn btn-danger m-1"
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
