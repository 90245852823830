import {useEffect,useState} from 'react';
import {url} from '../../App';

export default function useAuthorByID(id) {
    const [author, setAuthor] = useState([]);

    useEffect(() => {
      if(!id){
        setAuthor([]);
      }
      else{
        fetch(`${url}/api/author/${id}`)
        .then(res => res.json())
        .then(result => {
          setAuthor(result);
        });
      }
    }, [id]);

return [ author];

}

