import React from 'react';
import { Link, useHistory, useLocation} from 'react-router-dom';
import useCategories from '../../../Hooks/useCategories';
import { url } from '../../../App';

const Navbar = () => {
  const [ categories ] = useCategories();
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  //location redirect
  const history = useHistory();
  const location = useLocation();

  //logout function
  const logout = () =>{
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    fetch(`${url}/logout/`, {
      method: "POST",
      headers: {
        "Authorization": "Token "+token
      },
    })
    .then(()=>{
      if(location?.pathname==='/'){
        history.push('/');
      }
      else{
        history.push('/');
      }
    })
  }
  
  return (
    <>
      <div className="container p-1 border-0 rounded ">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container-fluid custom-border-color  ">
            {/* display category dropdown on Tab & PC */}
            <div className="dropdown d-none d-sm-block">
              <button
                className="btn btn-outline-none fw-medium text-dark dropdown-toggle nav-categories"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                ক্যাটাগরী
              </button>
              <ul
                className="dropdown-menu shadow"
                aria-labelledby="dropdownMenuButton2"
              >
                {categories && 
                  <li className="btn-list dropend">
                  {categories?.map((category) => (
                    <>
                    <div className="btn-group">
                      <button className="btn btn-sm" type="button">
                        <Link to={`/category/${category.name}`} 
                        key={category?.id} >
                          {category?.name}
                        </Link>                      
                      </button>
                      <button type="button" className="btn-sm bg-white dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="visually-hidden">Toggle Dropdown</span>
                      </button>
                      {(category?.children) &&
                        <ul className="dropdown-menu">
                          <li>
                          {category?.children.map((child) => (
                              <Link className="text-white" to={`/category/${child.name}`}>
                                <span className="dropdown-item fs-6 fw-medium">{child?.name}</span>
                              </Link>
                            ))}
                          </li>
                        </ul>
                        }
                      </div>
                    </> 
                  ))}
                  </li>
                }
              </ul>
            </div>
            {/* display category dropdown on Phone */}
            <div className="dropdown d-sm-none d-block custom-text">
              <button
                className="btn btn-outline-none fw-medium text-dark dropdown-toggle nav-categories"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                ক্যাটাগরী
              </button>
              <ul
                className="dropdown-menu shadow"
                aria-labelledby="dropdownMenuButton2"
              >
                {categories && 
                  <li className="btn-list dropdown custom-scroll">
                  {categories?.map((category) => (
                    <>
                    <Link to={`/category/${category.name}`}
                    className="dropdown-item  custom-text"
                    key={category.id} >{category?.name}</Link>
                    {(category?.children) &&
                      <ul className="">
                        <li className="">
                        {category?.children.map((child) => (
                            <Link className="custom-text" to={`/category/${child.name}`}>
                              <span className="dropdown-item fs-6 fw-light custom-text">{child?.name}</span>
                            </Link>
                        ))}
                        </li>
                      </ul>
                    }
                    </> 
                  ))}
                  </li>
                }
              </ul>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav mx-auto">
                <Link to="/books"
                  className="nav-link active text-dark fw-medium fs-6"
                  aria-current="page"
                >
                  বই
                </Link>
                <Link to="/electronics" className="nav-link text-dark fw-medium fs-6">ইলেক্ট্রনিক্স</Link>
                <Link to="/stationary" className="nav-link text-dark fw-medium fs-6">স্টেশনারী</Link>
                <Link to="/" className="nav-link text-dark fw-medium fs-6">প্রিন্টিং সার্ভিস</Link>
                <Link to="/" className="nav-link text-dark fw-medium fs-6">কাস্টম অর্ডার</Link>
                {
                  user?.phone_number && 
                  <Link to="/order" className="nav-link text-dark fw-medium fs-6">অর্ডার</Link>
                }
                {
                  user?.phone_number ? 
                  <button className="bg-white text-dark fw-medium fs-6" onClick={logout}>সাইন আউট</button>
                  :
                  <Link to="/login" className="nav-link text-dark fw-medium fs-6">সাইন ইন</Link>
                }
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
