import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useLogos() {
    const [logos, setLogos] = useState([]);
    const [count, setCount] = useState([0]);


    useEffect(() => {
        fetch(`${url}/api/logo/`)
       .then(res => res.json())
       .then(result => {
         setLogos(result)
         setCount(result.length);
       });
     }, []);

return [ logos, count ];

}

