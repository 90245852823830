import {useEffect,useState} from 'react';
import {url} from '../App';

export default function useCategoryAllBooks(categoryName) {
    const [books, setCategoryBooks] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [displayBooks, setDisplayBooks] = useState([]);
    const [offset, setOffset] = useState(0);

    const handlePageClick=(e)=>{
      const selectedPage = e.selected;
      setOffset(selectedPage*20);
    }
    useEffect(() => {
      if(!categoryName){
        setCategoryBooks([]);
      }
      else{
        fetch(`${url}/category-all-book/${categoryName}/`)
        .then(res => res.json())
        .then(result => {
            setCategoryBooks(result);
            const pageNumber = (Math.floor(result.length/20))+1;
            setPageCount(pageNumber);
            setDisplayBooks(result.slice(offset, (offset+(20*1))));
        });
      }  
    }, [categoryName, offset]);

    return [ books, pageCount, handlePageClick, offset, displayBooks];

}