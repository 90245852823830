import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useStationary() {
    const [stationaries, setStationaries] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [displayItems, setDisplayItems] = useState([]);
    const [offset, setOffset] = useState(0);

    const handlePageClick=(e)=>{
        const selectedPage = e.selected;
        setOffset(selectedPage*20);
    }

    useEffect(() => {
        fetch(`${url}/all-product/stationary/`)
       .then(res => res.json())
       .then(result => {
        setStationaries(result);
        const pageNumber = (Math.floor(result.length/20))+1;
        setPageCount(pageNumber);
        setDisplayItems(result.slice(offset, (offset+(20*1))));
      });
    }, [offset]);

return [ stationaries, pageCount, handlePageClick, offset, displayItems ];

}