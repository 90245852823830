import React from 'react';
import { NavLink } from 'react-router-dom';
import useLocalWishlist from '../../../Hooks/useLocalWishlist';
import { addToDb, removeFromWishlist } from '../../../Utilities/LocalStorage';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import '../common.css';

const Wishlist = ({ products }) => {
  const [WishlistItem, setWishlistItem] = useLocalWishlist(products);

  const handleAddToCart = (product) => {
    const newWishlist = WishlistItem.filter((item) => item.id !== product.id);
    setWishlistItem(newWishlist);
    addToDb(product.id);
    removeFromWishlist(product.id);
    window.alert('Successfully added!');
  };

  const handleRemoveWish = (id) => {
    const newWishlist = WishlistItem.filter((product) => product.id !== id);
    setWishlistItem(newWishlist);
    removeFromWishlist(id);
    window.alert('Successfully removed!');
  };

  return (
    <>
      <Header WishlistItem={WishlistItem}></Header>
      <section className=" mt-5 mb-5">
        <div className="container p-2 w-100 mx-auto border-0 rounded shadow mb-4 bg-white ">
          {WishlistItem.length === 0 ? 
            <h1 className="no-items product text-center heading fw-bold">
              আপনার উইশলিস্টে কোন আইটেম পাওয়া যায়নি !
            </h1>
            : 
            <>
              <h4 className="m-2 text-center heading fw-bold">আপনার উইশলিস্ট</h4>
              <hr className="mx-auto w-25 heading heading" />
              <div className="bg-white table-responsive">
              <table className="mb-3 table striped bordered table-striped table-hover">
                <thead className="">
                    <tr className="heading text-center">
                      <th scope="col">ছবি</th>
                      <th scope="col">প্রোডাক্ট</th>
                      <th scope="col">মূল্য</th>
                      <th scope="col">যুক্ত/ডিলিট</th>
                    </tr>
                  </thead>
                <tbody className='text-center'>
                  {WishlistItem.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img src={item.image} height="70px" alt="" />
                      </td>
                      {item?.type === 'books' && 
                      <td>
                        <NavLink to={`/books/${item.id}`}>
                          {item.english_title}
                        </NavLink>
                      </td>
                      }
                      {item?.type === 'electronics' && 
                      <td>
                        <NavLink to={`/electronics/${item.id}`}>
                          {item.english_title}
                        </NavLink>
                      </td>
                      }
                      {item?.type === 'stationary' && 
                      <td>
                        <NavLink to={`/stationary/${item.id}`}>
                          {item.english_title}
                        </NavLink>
                      </td>
                      }
                      {(item?.discount_price===0 && item?.regular_price===0) ?
                        <td></td>
                        :
                        <>
                        {item?.discount_price!==0 ?
                        <td>{item?.discount_price} টাকা</td>
                        :
                        <td>{item?.regular_price} টাকা</td>
                        }
                        </>
                      }
                      <td>
                        <button
                          className="btn btn-outline-success  rounded me-1 m-1"
                          onClick={() => handleAddToCart(item)}
                        >
                          <i className="fa-solid fa-bag-shopping"></i>
                        </button>

                        <button
                          className="btn btn-outline-danger rounded m-1"
                          onClick={() => handleRemoveWish(item.id)}
                        >
                          <i className="fa-solid fa-trash "></i>
                        </button>
                      </td>
                    </tr>
                  ))} 
                </tbody>
              </table>
              </div>
            </>
          }
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Wishlist;
