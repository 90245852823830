import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useAllUsers() {
    const [users, setUsers] = useState([]);
    const [pageCount, setPageCount] = useState(0);


    useEffect(() => {
        fetch(`${url}/api/user/`)
       .then(res => res.json())
       .then(result => {
         setUsers(result);
         const pageNumber = Math.ceil(result.length/20);
         setPageCount(pageNumber);
       });
     }, []);

return [ users, pageCount];

}

