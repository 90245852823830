import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useBCSChild() {
    const [bcsChilds, setBcsChilds] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/category/?name=বিসিএস-ব্যাংক`)
       .then(res => res.json())
       .then(result => {
        setBcsChilds(result[0].children);
       });
    }, []);

return [ bcsChilds ];

}