import React, {useState} from 'react';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import {useForm} from 'react-hook-form';
import { clearTheCart, getStoredCart } from '../../Utilities/LocalStorage';
import useLocalCart from '../../Hooks/useLocalCart';
import { useHistory } from 'react-router-dom';
import { url } from '../../App';
import useAllUsers from '../../Hooks/useAllUsers';


export default function Checkout({ products}) {
  const [users] = useAllUsers();
  const [deliveryOption, setDeliveryOption] = useState('');
  const [paymentOption, setPaymentOption] = useState('');
  const [deliveryCharge, setDeliveryCharge] = useState(60);
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const savedCart = getStoredCart();
  const [CartItem] = useLocalCart(products);
  const history = useHistory();
  const phone = JSON.parse(localStorage.getItem('user'));
  let user = users.find(user => user.phone_number === phone?.phone_number);

  //total price calculation depending on cart items
  const totalPrice = CartItem.reduce(
    (price, item) => price + item?.quantity * (item?.discount_price || item?.regular_price), 0
  );

  //delivery option selection function
  const selectDeliveryOption = (e) =>{
    if(e.target.value==='হোম ডেলিভারি(ঢাকা সিটির ভেতরে)'){
      setDeliveryCharge(60);
    }
    if(e.target.value==='কুরিয়ার'){
      setDeliveryCharge(80);
    }
    if(e.target.value==='হোম ডেলিভারি(ঢাকা সিটির বাইরে)'){
      setDeliveryCharge(130);
    }
    setDeliveryOption(e.target.value);
  }

  //payment option selection function
  const selectPaymentOption = (e) =>{
    setPaymentOption(e.target.value);
  }

  //onSubmit function
  const onSubmit = data => {

    //set default name
    if(!data.name){
      data.name = user.name;
    }
    //set default phone number
    if(!data.phone_number){
      data.phone_number = user.phone_number;
    }
    //set cart items into orderItems 
    data.orderItems = CartItem;
    //set default savedCart
    data.savedCart = savedCart;
    //set subtotal value
    data.subtotal = totalPrice;
    //set delivery_charge
    data.delivery_charge = deliveryCharge;
    //set total value
    data.total = totalPrice+data.delivery_charge;
    //set cash on delivery
    if(data.payment_option==='ক্যাশ অন ডেলিভারী'){
      data.cash_on_delivery = true;
    }
    else{
      data.cash_on_delivery = false;
    }
    //set default status
    data.status = "Pending";

    fetch(`${url}/checkout/`, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then((res)=> res.json())
    .then((result) => {
      if(result.status===400){
        alert(result.message);
        reset();
      }
      else{

        let message = `Nilkhet Boi Ghor, Dear ${data.name}, Your Order is placed successfully . We will contact soon. To Buy more www.nilkhetboighor.com`

        //third-party url for order placed
        fetch(`https://24bulksms.com/24bulksms/api/otp-api-sms-send?sender_id=377&api_key=175655169427363520230131091716pmcvWfz5GV&mobile_no=${data.phone_number}&message=${message}&user_email=nilkhetboighor.2020@gmail.com`)
        .then((res)=>res.json())

        alert('অভিনন্দন আপনার অর্ডারটি সফলভাবে প্লেসড হয়েছে। দ্রুত ডেলিভারির ব্যবস্থা করা হবে।');
        clearTheCart();
        reset();
        history.push('/order');
      }
    });
  }

  return (
    <>
    <Header ></Header>
    <section className='container mx-auto p-2 mb-5 mt-3'>
      <div className="row m-2 p-2">
        <div className="col bg-white p-2 border rounded ">
          <h4 className="text-center p-2 border-bg text-white">বিলিং এড্রেস</h4>
          <form className="container " onSubmit={handleSubmit(onSubmit)} >
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 form-label">নাম :</label>
              <input
                  {...register("name")}
                  defaultValue={user?.name}
                  type="text"
                  placeholder=""
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded"  
              />
              {errors.name?.type === 'required' && <p role="alert" className="text-danger"><small>নাম অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">ফোন নাম্বার :</label>
              <input
                  {...register("phone_number")}
                  defaultValue={user?.phone_number}
                  type="text"
                  placeholder=""
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
              />
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">বিকল্প ফোন নাম্বার :</label>
              <input
                {...register("optional_phone_number")}
                type="text"
                placeholder=""
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded " 
              />
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">ই-মেইল:</label>
              <input
                {...register("email")}
                type="email"
                placeholder=""
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded " 
              />
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">জেলা:</label>
              <input
                {...register("district", {required: true})}
                type="text"
                placeholder="জেলার নাম লিখুন" 
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
              />
              {errors.district?.type === 'required' && <p role="alert" className="text-danger"><small>জেলা অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">উপজেলা/ থানা:</label>
              <input
                {...register("upazila_thana", {required: true})}
                type="text"
                placeholder="উপজেলা/ থানার নাম লিখুন"
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
              />
              {errors.upazila_thana?.type === 'required' && <p role="alert" className="text-danger"><small>উপজেলা/ থানা অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">ডেলিভারি এড্রেস:</label>
              <input
                {...register("delivery_address", {required: true})}
                type="text"
                placeholder="বাসা/ফ্লাট নং, রাস্তা নং, এলাকার নাম লিখুন" 
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
              />
              {errors.delivery_address?.type === 'required' && <p role="alert" className="text-danger"><small>ডেলিভারি এড্রেস অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className='row mb-3'>
                <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">ডেলিভারি অপশন:</label>
                <select {...register("delivery_option", {required: true})}
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "  onChange={(e)=>selectDeliveryOption(e)}>
                  <option selected disabled value=''>একটি ডেলিভারি অপশন নির্বাচন করুন</option>
                  <option value="হোম ডেলিভারি(ঢাকা সিটির ভেতরে)">হোম ডেলিভারি(ঢাকা সিটির ভেতরে)</option>
                  <option value="কুরিয়ার">কুরিয়ার(ফুল পেমেন্ট করতে হবে)</option>
                  <option value="হোম ডেলিভারি(ঢাকা সিটির বাইরে)">হোম ডেলিভারি(ঢাকা সিটির বাইরে, নুন্যতম ডেলিভারি চার্জ আগে পে করতে হবে)</option>
                </select>
                {errors.delivery_option?.type === 'required' && <p role="alert" className="text-danger"><small>ডেলিভারি অপশন অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className='row mb-3'>
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">পেমেন্ট অপশন:</label>
              <select {...register("payment_option", {required: true})} 
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded " onChange={(e)=>selectPaymentOption(e)}>
                <option selected disabled value=''>একটি পেমেন্ট অপশন নির্বাচন করুন</option>
                  <option value="ক্যাশ অন ডেলিভারী">ক্যাশ অন ডেলিভারী</option>
                  <option value="বিকাশ পেমেন্ট">বিকাশ পেমেন্ট</option>
                  <option value="নগদ পেমেন্ট">নগদ পেমেন্ট</option>
                  <option value="রকেট পেমেন্ট">রকেট পেমেন্ট</option>
              </select>
              {errors.payment_option?.type === 'required' && <p role="alert" className="text-danger"><small>পেমেন্ট অপশন অত্যাবশ্যকিয়*</small></p>}
            </div>
            {(paymentOption==='বিকাশ পেমেন্ট') && 
            <>
            <p role="alert" className="text-success fw-bold"><small>নীলক্ষেত বইঘর এর পারসোনাল নাম্বার <strong className='text-danger'>01736964867</strong> এ <strong className='text-danger'>Send Money</strong> করে ট্রানসেকশন আইডি লিখে অর্ডার প্লেস করুন*</small></p>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">বিকাশ পেমেন্ট আইডি:</label>
              <input
                {...register("bkash_transaction_ID", {required: true})}
                type="text"
                placeholder="" 
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
              />
              {errors.bkash_transaction_ID?.type === 'required' && <p role="alert" className="text-danger"><small>বিকাশ পেমেন্ট আইডি অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">পেমেন্ট এমাউন্ট:</label>
              <input
                {...register("transaction_amount", {required: true})}
                type="text"
                placeholder="" 
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
              />
            {errors.transaction_amount?.type === 'required' && <p role="alert" className="text-danger"><small>পেমেন্ট এমাউন্ট অত্যাবশ্যকিয়*</small></p>}
            </div>
            </>
            }
            {(paymentOption==='নগদ পেমেন্ট') && 
            <>
            <p role="alert" className="text-success fw-bold"><small>নীলক্ষেত বইঘর এর পারসোনাল নাম্বার <strong className='text-danger'>01736964867</strong> এ <strong className='text-danger'>Send Money</strong> করে ট্রানসেকশন আইডি লিখে অর্ডার প্লেস করুন*</small></p>
            <div className="row mb-3">
                <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">নগদ পেমেন্ট আইডি:</label>
                <input
                  {...register("nagad_transaction_ID", {required: true})}
                  type="text"
                  placeholder="" 
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
                />
                {errors.bkash_transaction_ID?.type === 'required' && <p role="alert" className="text-danger"><small>নগদ পেমেন্ট আইডি অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3">
                <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">পেমেন্ট এমাউন্ট:</label>
                <input
                  {...register("transaction_amount", {required: true})}
                  type="text"
                  placeholder="" 
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
                />
              {errors.transaction_amount?.type === 'required' && <p role="alert" className="text-danger"><small>পেমেন্ট এমাউন্ট অত্যাবশ্যকিয়*</small></p>}
            </div>
            </>
            }
            {(paymentOption==='রকেট পেমেন্ট') && 
            <>
            <p role="alert" className="text-success fw-bold"><small>নীলক্ষেত বইঘর এর পারসোনাল নাম্বার <strong className='text-danger'>01736964867</strong> এ <strong className='text-danger'>Send Money</strong> করে ট্রানসেকশন আইডি লিখে অর্ডার প্লেস করুন*</small></p>
            <div className="row mb-3">
                <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">রকেট পেমেন্ট আইডি:</label>
                <input
                  {...register("rocket_transaction_ID", {required: true})}
                  type="text"
                  placeholder="" 
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
                />
                {errors.bkash_transaction_ID?.type === 'required' && <p role="alert" className="text-danger"><small>রকেট পেমেন্ট আইডি অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3">
                <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">পেমেন্ট এমাউন্ট:</label>
                <input
                  {...register("transaction_amount", {required: true})}
                  type="text"
                  placeholder="" 
                  className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded "
                />
              {errors.transaction_amount?.type === 'required' && <p role="alert" className="text-danger"><small>পেমেন্ট এমাউন্ট অত্যাবশ্যকিয়*</small></p>}
            </div>
            </>
            }
            <div className="row mb-3">
              <label className="col-sm-12 col-md-4 col-lg-4 py-2 ">অর্ডার নির্দেশনা:</label>
              <input
                {...register("order_instruction")}
                type="text"
                placeholder=""
                className=" p-1 col-sm-12 col-md-8 col-lg-8 custom-border-outline rounded " 
              />
            </div>
            <div className='row mb-3 w-50 mx-auto py-2'>
              <input
                type="submit"
                value="চেকআউট"
                className="btn btn-success border border-bg rounded text-white"
              />
            </div>
          </form>
        </div>
        <div className="col bg-white  p-2  ">
          <div className="container w-100 mx-auto">
            <h4 className="text-center p-2 border-bg text-white">অর্ডার সামারী</h4>
            <div className=" p-2 border-0 rounded shadow mb-4 bg-white table-responsive ">
              <table className="mb-3 table ">
                <thead className="">
                  <tr className="heading text-center">
                    <th scope="col ">প্রোডাক্ট</th>
                    <th scope="col ">মূল্য</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {CartItem.map((item) => (
                    <tr key={item.id}>
                      <td>{item.english_title} * {item.quantity}</td>
                      <td>{item?.discount_price || item.regular_price * item.quantity} টাকা</td>
                    </tr>
                  ))}
                  <tr>
                    <td className='fw-bold'>প্রোডাক্টের মোট মূল্য</td>
                    <td>{totalPrice} টাকা</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>পরিবহন চার্জ</td>
                    <td>{deliveryCharge} টাকা</td>
                  </tr>
                  <tr>
                    <td className='fw-bold text-dark fs-6'>সর্বোমোট</td>
                    <td className='fw-bold text-dark fs-6'>{totalPrice+deliveryCharge} টাকা</td>
                  </tr> 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
    </>
  )
}
