import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function useCategoryBooksSortByNew(categoryName) {
    const [sortedNewBooks, setSortedBooks] = useState([]);

    useEffect(() => {
      if(!categoryName){
        setSortedBooks([]);
      }
      else{
        fetch(`${url}/category-book/${categoryName}/?ordering=-updated_at`)
       .then(res => res.json())
       .then(result => {
        setSortedBooks(result);
       });
      } 
    }, [categoryName]);

return [ sortedNewBooks ];

}