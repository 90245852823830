import React from 'react';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import { url } from '../../../App';


export default function UpdateAuthor({authors}) {
  const [details, setDetails] = useState({});
  const { id } = useParams();
  const {register, handleSubmit, reset} = useForm();
  const history = useHistory();
  const [ selectedImage, setSelectedImage ] = useState(null);

  useEffect(() => {
    const matchAuthor = authors.find((author) => author.id == id);
    setDetails(matchAuthor);
  },[id, authors]);


  // author updated function
  const onSubmit = data => {
    let formData = new FormData();
    if(selectedImage!==null){
      formData.append('image', selectedImage);
    }
    if(!data.name){
      data.name = details?.name;
    }
    if(!data.description){
      data.description = details?.description;
    }
    if(!data.alt_text){
      if(!details?.alt_text){
        data.alt_text = data.name;
      }
      else{
        data.alt_text = details?.alt_text;
      }
    }
    formData.append('name', data.name);
    formData.append('description', data.description);
    formData.append('alt_text', data.alt_text);
    
    fetch(`${url}/api/author/${id}/`, {
      method: "PUT",
      body: formData,
    })
    .then(res => {
      if (!res.ok) throw res;
      else return res.json();
    })
    .then(getData => {
      alert("Author updated Successfully!");
      reset();
      history.push('/admin-authors'); 
    })
    .catch(err => {
      err.json().then(text => {
        if (text?.error) {
          console.log(text?.error);
          return;
        }
    })
      console.log(err.statusText);
    });
    
  }

  return (
    <div className="container mx-auto w-75 m-3 border-0 p-3 rounded shadow bg-light">
      <h3 className="text-center ">Update Author Information</h3>
      <hr className="w-50 mx-auto" />
      <form className="w-50 mx-auto " onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("name")}
          defaultValue={details?.name}
          placeholder="Author name"
          className="p-3 m-3 w-100 border border-success rounded"
        />
        <br />
        <input
          {...register("description")}
          defaultValue={details?.description}
          placeholder="Description"
          className="p-3 m-3 w-100 border border-success rounded"
        />
        <br />
        {details?.image && <img src={details?.image} alt={details?.alt_text} height="100px" width="100px" className="p-3"/>}
        <input
          {...register("image")}
          type="file"
          id="choose-file"
          placeholder="Upload an Author image"
          className="p-3 m-3 w-100 border border-success rounded"
          data-max-file-size="2M"
          name="upload"
          accept="image/*"
          onChange={(event) => {
          setSelectedImage(event.target.files[0]);
        }}
        />
        <br />
        <input
          {...register("alt_text")}
          defaultValue={details?.alt_text}
          placeholder="Alt-Text"
          className="p-3 m-3 w-100 border border-success rounded"
        />
        <br />
        <div className='d-flex'>
          <input
            type="submit"
            value="Update"
            className="btn btn-success w-100 border border-success rounded m-3"
          />
          <NavLink to='/admin-authors' className='text-white btn btn-danger w-50 border border-danger rounded m-3 '>Cancel <i className="fa-solid fa-close"></i></NavLink>
        </div>
      </form>
    </div>
  );
}
