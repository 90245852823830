import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useProgramming() {
    const [programmingBooks, setProgrammingBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/প্রোগ্রামিং-ফ্রিল্যান্সিং`)
       .then(res => res.json())
       .then(result => {
        setProgrammingBooks(result);
       });
    }, []);

return [ programmingBooks ];

}