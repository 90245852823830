import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useEngineeringChild() {
    const [engineeringChilds, setEngineeringChilds] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/category/?name=ইঞ্জিনিয়ারিং`)
       .then(res => res.json())
       .then(result => {
        setEngineeringChilds(result[0].children);
       });
    }, []);

return [ engineeringChilds ];

}