import React from 'react';
import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import MegaMenu from '../../common/MegaMenu/MegaMenu';
import PublisherPage from './PublisherPage';
import usePublishers from '../../../Hooks/usePublishers';
import Pagination from '../../Shared/Pagination/Pagination';

export default function PublishersPage({ CartItem }) {

  const [publishers, pageCount, handlePageClick, offset, displayData] = usePublishers();

  return (
    <>
      <Header CartItem={CartItem}></Header>
      <MegaMenu></MegaMenu>
      <div className="container mx-auto m-3 mb-5">
        <h4 className="pages-heading text-center fw-bold">আমাদের প্রকাশক</h4>
        <hr className="bg-success w-25 mx-auto" />
        {(publishers?.length===0) && <div className='vh-100'></div>}
        {(publishers?.length!==0) &&
        <>
          {displayData?.length<=5 ?
                <div  style={{minHeight:'700px', maxHeight:'2000px'}}>
                  <div className="g-4 row row-cols-1 row-cols-md-4 row-cols-lg-5 container mx-auto">
                  {displayData.map((publisher) => (
                    <PublisherPage key={publisher?.id} publisher={publisher}></PublisherPage>
                  ))}
                  </div>
                </div>
              :
              <div className="g-4 row row-cols-1 row-cols-md-4 row-cols-lg-5 container mx-auto">
              {displayData.map((publisher) => (
                    <PublisherPage key={publisher?.id} publisher={publisher}></PublisherPage>
              ))}
              </div>
          }
          <div className='container w-75 mx-auto mt-5'>              
            <Pagination pageCount={pageCount} handlePageClick={handlePageClick} offset={offset}></Pagination>
          </div>
        </>
        }
      </div>
      <Footer></Footer>
    </>
  );
}
