// use local storage as your db for now
const addToDb = (id) => {
  const exists = getDb();
  let local_cart = {};
  if (!exists) {
    local_cart[id] = 1;
  } else {
    local_cart = JSON.parse(exists);
    if (local_cart[id]) {
      const newCount = local_cart[id] + 1;
      local_cart[id] = newCount;
    } else {
      local_cart[id] = 1;
    }
  }
  updateDb(local_cart);
};

const getDb = () => localStorage.getItem('local_cart');
const updateDb = (cart) => {
  localStorage.setItem('local_cart', JSON.stringify(cart));
};

const removeFromDb = (id) => {
  const exists = getDb();

  if (!exists) {
  } else {
    const local_cart = JSON.parse(exists);
    if (local_cart[id] === 1) {
      delete local_cart[id];
    } else {
      const newCount = local_cart[id] - 1;
      local_cart[id] = newCount;
    }

    updateDb(local_cart);
  }
};

const getStoredCart = () => {
  const exists = getDb();
  return exists ? JSON.parse(exists) : {};
};

const clearTheCart = () => {
  localStorage.removeItem('local_cart');
};


// for wishlist
const addToWishlist = (id) => {
  const exists = getWishlist();
  let local_wishlist = {};
  if (!exists) {
    local_wishlist[id] = 1;
  } 
  else {
    local_wishlist = JSON.parse(exists);
    if (local_wishlist[id]) {
      const newCount = local_wishlist[id] + 1;
      local_wishlist[id] = newCount;
    } else {
      local_wishlist[id] = 1;
    }
  }
  updateWishlist(local_wishlist);
};

const getWishlist = () => localStorage.getItem('local_wishlist');
const updateWishlist = (wishlist) => {
  localStorage.setItem('local_wishlist', JSON.stringify(wishlist));
};

const removeFromWishlist = (id) => {
  const exists = getWishlist();

  if (!exists) {
  } else {
    const local_wishlist = JSON.parse(exists);
    if (local_wishlist[id] === 1) {
      delete local_wishlist[id];
    } else {
      const newCount = local_wishlist[id] - 1;
      local_wishlist[id] = newCount;
    }
    updateWishlist(local_wishlist);
  }
};

const getStoredWishlist = () => {
  const exists = getWishlist();
  return exists ? JSON.parse(exists) : {};
};

const clearTheWishlist = () => {
  localStorage.removeItem('local_wishlist');
};

export {
  getDb,
  addToDb,
  removeFromDb,
  clearTheCart,
  getStoredCart,
  addToWishlist,
  removeFromWishlist,
  clearTheWishlist,
  getStoredWishlist,
};