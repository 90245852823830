import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useBBAMBA() {
    const [bbaMbaBooks, setBbaMbaBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/বিবিএ-এমবিএ`)
       .then(res => res.json())
       .then(result => {
        setBbaMbaBooks(result);
       });
    }, []);

return [ bbaMbaBooks ];

}