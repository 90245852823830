import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function usePublisherBooksSortByLowPrice(publisherName) {
    const [sortedLowBooks, setSortedBooks] = useState([]);

    useEffect(() => {
      if(!publisherName){
        setSortedBooks([]);
      }
      else{
        fetch(`${url}/publisher-book/${publisherName}/?ordering=regular_price`)
       .then(res => res.json())
       .then(result => {
        setSortedBooks(result);
       });
      } 
    }, [publisherName]);

return [ sortedLowBooks ];

}