import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useAllOrders() {
    const [orders, setOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        fetch(`${url}/api/order/?ordering=-id`)
       .then(res => res.json())
       .then(result => {
         setOrders(result);
         const pageNumber = Math.ceil(result.length/20);
         setPageCount(pageNumber);
       });
    }, []);

return [ orders, pageCount ];

}

