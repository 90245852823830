import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useLawChild() {
    const [lawChilds, setLawChilds] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/category/?name=আইন`)
       .then(res => res.json())
       .then(result => {
        setLawChilds(result[0].children);
       });
    }, []);

return [ lawChilds ];

}