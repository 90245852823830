import React from 'react';
import './style.css';
import { NavLink , Link} from 'react-router-dom';
import defaultImage from '../../assets/images/defaultImage.jpeg';


const Publishers = ({publishers}) => {

  const Publishers = publishers.slice(0,6);

  return (
    <>
      <section className="authors background">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <i className="fas fa-user text-warning custom-icon"></i>
              <h4 className="text-center custom-h4">প্রকাশক</h4>
            </div>
            <div className=" ">
              <Link to="/publishers">
                <span>সবগুলো দেখুন</span>
              </Link>
              <i className="fa-solid fa-caret-right custom-view-icon"></i>
            </div>
          </div>

          <div className="row row-cols-sm-2 row-cols-1 row-cols-md-3 row-cols-lg-6 g-4">
            {Publishers?.map((publisher) => {
              return (
                <>
                  <div className="col ">
                    <div
                      className="card card-height border-1 h-100"
                      key={publisher?.id}
                      >
                      {!publisher?.image ?
                      <div className='card-body d-flex justify-content-center align-items-center h-100'>
                        <a href={`/publishers/${publisher.name}`}>
                          <img
                          className="publishers mx-auto rounded"
                          src={defaultImage}
                          alt=''
                          />
                        </a>
                      </div>
                      :
                        <div className='card-body d-flex justify-content-center align-items-center h-100'>
                          <a href={`/publishers/${publisher.name}`}>
                            <img
                            className="publishers mx-auto"
                            src={publisher?.image}
                            alt={publisher?.alt_text}
                            />
                          </a>
                        </div>
                      }
                      <div className="card-footer bg-white border-0 h-100">
                        <NavLink className="text-white" to={`/publishers/${publisher.name}`}>
                          <span className="card-title">{publisher?.name}</span>
                        </NavLink>
                      </div>
                    </div>
                </div>
              </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Publishers;
