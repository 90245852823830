import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function useElectronicsSortByStock() {
    const [sortedStockItems, setSortedItems] = useState([]);

    useEffect(() => {
        fetch(`${url}/all-product/electronics/?ordering=-units_stock`)
       .then(res => res.json())
       .then(result => {
        setSortedItems(result);
       });
    }, []);

return [ sortedStockItems ];

}