import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function useCategoryBooksSortByStock(categoryName) {
    const [sortedStockBooks, setSortedBooks] = useState([]);

    useEffect(() => {
      if(!categoryName){
        setSortedBooks([]);
      }
      else{
        fetch(`${url}/category-book/${categoryName}/?ordering=-units_stock`)
        .then(res => res.json())
        .then(result => {
         setSortedBooks(result);
        });
      }
    }, [categoryName]);

return [ sortedStockBooks ];

}