import React from 'react';
import "swiper/css/bundle";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Cart from './components/common/Cart/Cart';
import Books from './components/Books/Book/Books';
import BookDetail from './components/Books/BookDetail/BookDetail';
import Registration from './components/Registration/Registration';
import AdminPage from './components/AdminDashboard/AdminPage';
import Shop from './components/Shop/Shop';
import AdminLogo from './components/AdminDashboard/AdminLogo/AdminLogo';
import AdminBanner from './components/AdminDashboard/AdminBanner/AdminBanner';
import AdminAuthors from './components/AdminDashboard/AdminAuthors/AdminAuthors';
import UpdateAuthor from './components/AdminDashboard/AdminAuthors/UpdateAuthor';
import AdminPublishers from './components/AdminDashboard/AdminPublishers/AdminPublishers';
import AdminCategories from './components/AdminDashboard/AdminCategories/AdminCategories';
import AdminBooks from './components/AdminDashboard/AdminBooks/AdminBooks';
import UpdatePublisher from './components/AdminDashboard/AdminPublishers/UpdatePublisher';
import UpdateCategory from './components/AdminDashboard/AdminCategories/UpdateCategory';
import UpdateBook from './components/AdminDashboard/AdminBooks/UpdateBook';
import useLocalCart from './Hooks/useLocalCart';
import { addToDb } from './Utilities/LocalStorage';
import Wishlist from './components/common/Wishlist/Wishlist';
import Checkout from './components/Checkout/Checkout';
import Login from './components/Login/Login';
import AuthorsPage from './components/AuthorsPage/Authors/AuthorsPage';
import AuthorDetails from './components/AuthorsPage/AuthorDetails/AuthorDetails';
import PublishersPage from './components/PublishersPage/Publishers/PublishersPage';
import PublisherDetails from './components/PublishersPage/PublisherDetails/PublisherDetails';
import CategoryDetails from './components/CategoryPage/CategoryDetails/CategoryDetails';
import AdminOrders from './components/AdminDashboard/AdminOrders/AdminOrders';
import VerifyOTP from './components/Registration/VerifyOTP';
import ForgetPassword from './components/ForgetPassword/ForgetPassword';
import ResetPassword from './components/ForgetPassword/ResetPassword';
import MakeAdmin from './components/AdminDashboard/MakeAdmin/MakeAdmin';
import AdminElectronics from './components/AdminDashboard/AdminElectronics/AdminElectronics';
import UpdateElectronics from './components/AdminDashboard/AdminElectronics/UpdateElectronics';
import AdminStationary from './components/AdminDashboard/AdminStationary/AdminStationary';
import UpdateStationary from './components/AdminDashboard/AdminStationary/UpdateStationary';
import Electronics from './components/Electronics/Electronics/Electronics';
import useProducts from './Hooks/useProducts';
import Stationaries from './components/Stationary/Stationary/Stationaries';
import StationaryDetail from './components/Stationary/StationaryDetail/StationaryDetail';
import ElectronicsDetail from './components/Electronics/ElectronicsDetail/ElectronicsDetail';
import Order from './components/Order/Order';
import OrderInvoice from './components/AdminDashboard/AdminOrders/OrderInvoice';
import ScrollToTopButton from './components/ScrollToTop/ScrollToTopButton';
import Categories from './components/CategoryPage/Categories/Categories';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import NotFound from './components/NotFound/NotFound';
import AdminCustomers from './components/AdminDashboard/AdminCustomers/AdminCustomers';
import useAllCategories from './Hooks/useAllCategories';
import useAuthors from './Hooks/useAuthors';
import usePublishers from './Hooks/usePublishers';
import useElectronics from './Hooks/useElectronics';
import useElectronicCategory from './Hooks/useElectronicCategory';
import useStationary from './Hooks/useStationary';
import useStationaryCategory from './Hooks/useStationaryCategory';
import useAllOrders from './Hooks/useAllOrders';
import useBooks from './Hooks/useBooks';
import useAllAdmin from './Hooks/useAllAdmin';

export const url = "https://api.nilkhetboighor.com";
// export const url = "http://127.0.0.1:8000";


function App() {
  const [ electronics] = useElectronics();
  const [electronicCategory]  = useElectronicCategory({});
  const [stationaries] = useStationary();
  const [ stationaryCategory ] = useStationaryCategory({});
  const [ books, pageCount, handlePageClick, offset, displayBooks] = useBooks();
  const [ categories] = useAllCategories([]);
  const [authors] = useAuthors();
  const [publishers] = usePublishers();
  const [ admins] = useAllAdmin();
  const [orders] = useAllOrders([]);
  const [ products ]= useProducts();
  const [CartItem, setCartItem] = useLocalCart(products);

  const addToCart = (product) => {
    const newCart = [...CartItem, product];
    setCartItem(newCart);
    addToDb(product.id);
  };

  return (
    <>
      <Router>
        <ScrollToTop/>
        <ScrollToTopButton/>
        <Switch>
          <Route path="/" exact>
            <Shop authors={authors} publishers={publishers}/>
          </Route>
          <Route path="/register" exact>
            <Registration></Registration>
          </Route>
          <Route path="/verify-otp" exact>
            <VerifyOTP></VerifyOTP>
          </Route>
          <Route path="/login" exact>
            <Login></Login>
          </Route>
          <Route path="/forget-password" exact>
            <ForgetPassword></ForgetPassword>
          </Route>
          <Route path="/reset-password" exact>
            <ResetPassword></ResetPassword>
          </Route>
          <Route path="/cart" exact>
            <Cart products={products} />
          </Route>
          <Route path="/checkout" exact>
            <Checkout products={products}/>
          </Route>
          <Route path="/wishlist" exact>
            <Wishlist products={products} />
          </Route>
          <Route path="/books" exact>
            <Books books={books} pageCount={pageCount} handlePageClick={handlePageClick} offset={offset} displayBooks={displayBooks} authors={authors} publishers={publishers}></Books>
          </Route>
          <Route path="/electronics" exact>
            <Electronics electronics={electronics} electronicCategory={electronicCategory}></Electronics>
          </Route>
          <Route path="/stationary" exact>
            <Stationaries stationaries={stationaries} stationaryCategory={stationaryCategory}></Stationaries>
          </Route>
          <Route path="/order" exact>
            <Order></Order>
          </Route>
          <Route path='/categories' exact>
            <Categories></Categories>
          </Route>
          <Route path="/category/:categoryName" exact>
            <CategoryDetails CartItem={CartItem} addToCart={addToCart} authors={authors} publishers={publishers}></CategoryDetails>
          </Route>
          <Route path="/books/:bookID" exact>
            <BookDetail books={books} CartItem={CartItem} addToCart={addToCart}></BookDetail>
          </Route>
          <Route path="/electronics/:productID" exact>
            <ElectronicsDetail CartItem={CartItem} addToCart={addToCart}></ElectronicsDetail>
          </Route>
          <Route path="/stationary/:productID" exact>
            <StationaryDetail CartItem={CartItem} addToCart={addToCart}></StationaryDetail>
          </Route>
          <Route path="/authors" exact>
            <AuthorsPage CartItem={CartItem} authors={authors}></AuthorsPage>
          </Route>
          <Route path="/authors/:authorName" exact>
            <AuthorDetails CartItem={CartItem} addToCart={addToCart}></AuthorDetails>
          </Route>
          <Route path="/publishers" exact>
            <PublishersPage CartItem={CartItem} publishers={publishers}></PublishersPage>
          </Route>
          <Route path="/publishers/:publisherName" exact>
            <PublisherDetails CartItem={CartItem} addToCart={addToCart}></PublisherDetails>
          </Route>
          <Route path="/admin" exact>
            <AdminPage books={books}></AdminPage>
          </Route>
          <Route path="/admin-logo" exact>
            <AdminLogo></AdminLogo>
          </Route>
          <Route path="/admin-banner" exact>
            <AdminBanner></AdminBanner>
          </Route>
          <Route path="/admin-authors" exact>
            <AdminAuthors authors={authors}></AdminAuthors>
          </Route>
          <Route path="/update-author/:id" exact>
            <UpdateAuthor authors={authors}></UpdateAuthor>
          </Route>
          <Route path="/admin-publishers" exact>
            <AdminPublishers publishers={publishers}></AdminPublishers>
          </Route>
          <Route path="/update-publisher/:id" exact>
            <UpdatePublisher publishers={publishers}></UpdatePublisher>
          </Route>
          <Route path="/admin-customers" exact>
            <AdminCustomers></AdminCustomers>
          </Route>
          <Route path="/make-admin" exact>
            <MakeAdmin admins={admins}></MakeAdmin>
          </Route>
          <Route path="/admin-categories" exact>
            <AdminCategories categories={categories}></AdminCategories>
          </Route>
          <Route path="/update-category/:id">
            <UpdateCategory categories={categories}></UpdateCategory>
          </Route>
          <Route path="/admin-books" exact>
            <AdminBooks books={books} authors={authors} publishers={publishers}></AdminBooks>
          </Route>
          <Route path="/update-book/:id" exact>
            <UpdateBook  books={books} categories={categories} authors={authors} publishers={publishers}></UpdateBook>
          </Route>
          <Route path="/admin-electronics" exact>
            <AdminElectronics electronics={electronics} electronicCategory={electronicCategory}></AdminElectronics>
          </Route>
          <Route path="/update-electronics/:id" exact>
            <UpdateElectronics electronics={electronics}></UpdateElectronics>
          </Route>
          <Route path="/admin-stationary" exact>
            <AdminStationary stationaries={stationaries} stationaryCategory={stationaryCategory}></AdminStationary>
          </Route>
          <Route path="/update-stationary/:id" exact>
            <UpdateStationary stationaries={stationaries}></UpdateStationary>
          </Route>
          <Route path="/admin-orders" exact>
            <AdminOrders orders={orders}></AdminOrders>
          </Route>
          <Route path="/order-invoice/:id" exact>
            <OrderInvoice></OrderInvoice>
          </Route>
          <Route path='*'><NotFound/></Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
