import React, {useState} from 'react';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import { useForm } from 'react-hook-form';
import { url } from '../../../App';
import useAllCustomers from '../../../Hooks/useAllCustomers';


export default function AdminCustomers() {
  const [customers] = useAllCustomers([]);
  const [phone_number, setPhoneNumber] = useState('');
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [result, setResults] = useState(0);
  const {register, handleSubmit} = useForm();

  // searching function
  const onSearch = data =>{

    setPhoneNumber(data.phone_number);
    if(data.phone_number===''){
      setSearchCustomers([]);
      setResults(0);
    }
    else{
      fetch(`${url}/api/user/?is_admin=false&phone_number=${data.phone_number}`)
        .then(res => res.json())
        .then(result => {
          if(result.length===0){
            setResults(1);
          }
          else{
            setResults(2);
            setSearchCustomers(result);
          }
        });
    }  
  }

  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container ">
          <AdminNavbar></AdminNavbar>
          <h3 className="text-center pt-3">Customers</h3>
          <hr className=" update-title-hr" />
          {/* search customer */}
          <div className="w-50 mx-auto mb-3">
            <form className='container mx-auto' onSubmit={handleSubmit(onSearch)}>
              <div className="row mb-3">
                <div className='col-sm-8 col-md-9 col-lg-9 me-2 border border-success rounded-pill p-1 bg-white'>
                  <i className="fa fa-search text-center p-1"></i>
                  <input
                    {...register("phone_number")}
                    type="text"
                    className="fs-6 p-1 w-75"
                    placeholder="Search Customer by Phone Number"
                    />
                </div>
                <button className='col-sm-3 col-md-2 col-lg-2 btn btn-outline-success rounded bg-white text-success fw-bold' type='submit' value='submit'>Search</button>
              </div>
            </form>
          </div>
          {/* show search result */}
          {phone_number===''?
            <></>
            :
            <>
              {result===0 ?
                <></>
                :
                <>
                {result===1?
                  <div className='container p-2 mb-4 bg-white table-responsive border rounded search-div '>
                    <p className='fw-bold custom-h4 p-2 fs-6'>Sorry, No result found!</p>
                  </div>
                  :
                  <>
                    <div className="container p-2 border-0 rounded shadow mb-5 bg-white table-responsive-sm">
                      <h4 className="text-center heading">Searching List</h4>
                      <hr className="w-25 mx-auto heading" />
                      <table className="mb-3 table striped bordered table-striped table-hover">
                        <thead className="custom-search">
                          <tr className="heading">
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Phone No.</th>
                          </tr>
                        </thead>
                        <tbody className='custom-search'>
                          {searchCustomers.map((customer) => (
                            <tr key={customer?.id}>
                              <td>{customer?.id}</td>
                              <td>{customer?.name}</td>
                              <td>{customer?.phone_number}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                }
                </>
              }
            </>
          }
          {/* show customer list */}
          <div className="container p-2 border-0 rounded shadow mb-4 bg-white table-responsive-sm">
            <h4 className="text-center"> Customers List</h4>
            <hr className="w-25 mx-auto" />
            <table className="mb-3 table striped bordered text-center table-striped table-hover">
              <thead className="custom-tbody">
                <tr className="text-success">
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone No.</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody className="custom-tbody">
                {customers?.map((customer) => (
                <tr key={customer?.id}>
                  <td>{customer?.id}</td>
                  <td>{customer?.name}</td>
                  <td>{customer?.phone_number}</td>
                  <td>{customer.is_admin===false && "Customer"}</td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
