import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useProductByID(id) {
    const [product, setProduct] = useState({});


    useEffect(() => {
        if(!id){
            setProduct([]);
        }
         else{
            fetch(`${url}/api/product/${id}/`)
            .then(res => res.json())
            .then(result => {
             setProduct(result);
             });
         }
    }, [id]);

return [ product];

}

