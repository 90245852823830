import React from 'react';
import useUserOrder from '../../Hooks/useUserOrder';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import OrderItems from './OrderItems';
import './Order.css';

const Order = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [orders] = useUserOrder(user?.phone_number);

  return (
    <>
      <Header></Header>
      <section className=" mt-5 mb-5">
        <div className="container p-2 mx-auto mb-4 bg-white h-100">
          {(orders?.length===0)  ? 
            <h1 className="no-items product text-center heading">
              আপনার কোন অর্ডার আইটেম পাওয়া যায়নি !
            </h1>
            : 
            <>
              <h4 className="m-2 text-center heading fw-bold">আপনার অর্ডার লিস্ট</h4>
              <hr className="mx-auto w-25 mb-4 heading" />
              <div className='g-4 row row-cols-1 row-cols-md-1 row-cols-lg-2 mx-auto'>
                {orders?.map((order) => (
                  <OrderItems key={order?.id} order={order} ></OrderItems>
                ))}
              </div>
            </>
          }
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Order;