import React from 'react';
import { NavLink } from 'react-router-dom';

const Book = ({ book, handleAddToCart }) => {
  return (
    <div className="col">
      <div className="card h-100 ">
        <a href={`/books/${book.id}`}>
          <img className="card-img-top image-watermark" height="200px" src={book?.image} alt={book?.alt_text}/>
        </a>
        <div className="card-body h-100">
          <h5 className="card-title text-warning"><NavLink to={`/books/${book.id}`}>{book?.english_title}</NavLink></h5>
          {(book?.discount_price===0 || book?.discount_price===null) ?  <p className="card-text text-start text-dark">{book?.regular_price} টাকা</p>
          :
          <div className="d-inline-flex ">
            <div className="me-2">
              <p className="card-text text-start text-dark text-decoration-line-through">{book?.regular_price} টাকা</p>
            </div>
            <div className="ms-2">
              <p className="card-text text-start text-danger">{book?.discount_price} টাকা</p>
            </div>
          </div>
          } 
        </div>
        {(book?.units_stock <=10 && book?.units_stock >=1) &&
              <div className="text-center"><strong className='text-primary'>*** Last {book?.units_stock}pcs in stock! ***</strong></div>
        }
        <div className="card-footer bg-white border-0">
            <button className="btn col-12 rounded readmore my-1">
            <NavLink className="text-white fs-6" to={`/books/${book.id}`} >
              Read more
            </NavLink>
            </button>
            {book?.units_stock!==0 ?
            <button className="btn col-12 rounded addtocart" onClick={() => handleAddToCart(book)}>
              <span className='text-white fs-6'>Add to cart <i className="fa fa-plus"></i></span>
            </button>
            :
            <button className="btn col-12 rounded addtocart disabled">
                <span className='text-white fs-6'>Stock out!</span>
            </button>
            }
        </div>
      </div>
    </div>
  );
};
export default Book;
