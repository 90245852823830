import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function useCategoryBooksSortByLowPrice(categoryName) {
    const [sortedLowBooks, setSortedBooks] = useState([]);

    useEffect(() => {
      if(!categoryName){
        setSortedBooks([]);
      }
      else{
        fetch(`${url}/category-book/${categoryName}/?ordering=regular_price`)
        .then(res => res.json())
        .then(result => {
         setSortedBooks(result);
        });
      }
    }, [categoryName]);

return [ sortedLowBooks ];

}