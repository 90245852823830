import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useCompetitiveChild() {
    const [competitiveChilds, setCompetitiveChilds] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/category/?name=কম্পিটিটিভ-এক্সাম`)
       .then(res => res.json())
       .then(result => {
        setCompetitiveChilds(result[0].children);
       });
    }, []);

return [ competitiveChilds ];

}