import React from 'react';
import logo from '../../assets/images/book-logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {getStoredCart, getStoredWishlist} from '../../../Utilities/LocalStorage';
import { useState , useEffect} from 'react';
import './Search.css';
import { url } from '../../../App';
import useAdmin from '../../../Hooks/useAdmin';
import useAuthors from '../../../Hooks/useAuthors';

const Search = () => {
  const [title, setTitle] = useState('');
  const [searchBooks, setSearchBooks] = useState([]);
  const [result, setResults] = useState(0);
  const user = JSON.parse(localStorage.getItem('user'));
  const [admin] = useAdmin(user?.phone_number);
  const token = localStorage.getItem('token');
  const [cartLength, setCartLength] = useState(0);
  const [wishlistLength, setWishlistLength] = useState(0);
  const [authors] = useAuthors();
  
  //location redirect
  const history = useHistory();
  const location = useLocation();

  // for cart
  const cart_length = Object.keys(getStoredCart()).length;

  // for wishlist
  const wishlist_length = Object.keys(getStoredWishlist()).length;

  useEffect(()=>{
    setCartLength(cart_length);
    setWishlistLength(wishlist_length);
  },[cart_length, wishlist_length]);

  // handle focusOut function
   const handleFocusOut = ()=>{
     setTitle('');
   }
  
  // searching function
  useEffect(() => {
    if(title===''){
      setSearchBooks([]);
      setResults(0);
    }
    else{
    fetch(`${url}/all-product/books/?search=${title}`)
      .then(res => res.json())
      .then(result => {
        if(result.length===0){
          setResults(1);
        }
        else{
          setResults(2);
          // setSearchBooks(result);
          setSearchBooks(result?.slice(0, 20));
        }
      });
    }
  }, [title]);

  //logout function
  const logout = () =>{
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    fetch(`${url}/logout/`, {
      method: "POST",
      headers: {
        "Authorization": "Token "+token
      },
    })
    .then(()=>{
      if(location?.pathname==='/'){
        history.push('/');
      }
      else{
        history.push('/');
      }
    })
  }


  return (
    <>
      <section className="search container">
        <div className=" d_flex  row gx-0 d-flex flex-column flex-md-row justify-content-center align-items-center"   onMouseLeave={handleFocusOut}>
          <div className="col-12 col-md-2 d-flex justify-content-center justify-content-md-start align-items-center">
            <Link to="/">
              <img
                src={logo}
                height="70px"
                width="85px"
                alt="Nilkhet Boighor"
              />
            </Link>
          </div>
          <div className=" d-flex justify-content-center align-items-center position-relative  my-3 my-md-0 col-10 col-md-5" >
            <div className='mx-auto w-100 border p-2 rounded-pill'>
              <i className="fa fa-search px-2 px-md-3"></i>
              <input
                type="text"
                value={title}
                className="fs-6 w-75"
                placeholder="Search by Book, Author, Publisher, Category"
                onChange={(e)=>setTitle(e.target.value)}
              />
            </div>
            {title===''?
              <></>
              :
              <>
              {result===0 ?
                <></>
                :
                <>
                {result===1?
                  <div className='bg-white table-responsive position-absolute border rounded search-div '>
                    <p className='fw-bold custom-h4 p-2 fs-6'>Sorry, No result found!</p>
                  </div>
                  :
                  <>
                    {searchBooks?.length>=4 ?
                      <div className='bg-white table-responsive position-absolute border rounded search-div w-100'>
                        <ul className='list-group list-group-flush w-100 fixed-header'>
                        {searchBooks.map((item) => (
                          <li key={item.id} className='container mx-auto list-group-item'>
                            <div className='row'>
                              <div className='col-lg-2 col-md-3 col-sm-12 '>
                                <Link to={`/books/${item.id}`}>
                                  <img src={item.image} height="40px" alt="" />
                                </Link>
                              </div>
                              <div className='col-lg-7 col-md-6 col-sm-12 text-start'>
                                <ul>
                                  <li>
                                    <Link to={`/books/${item.id}`}>
                                      {item.english_title}
                                    </Link>
                                  </li>
                                    {authors.map((author)=>(
                                      <li key={author.id}>
                                      {author.id===item.author &&
                                        <Link to={`/books/${item.id}`}  className='text-secondary'>
                                          {author.name}
                                        </Link>
                                      }
                                      </li>
                                    ))}
                                </ul>
                              </div>
                              <div className='col-lg-3 col-md-3 col-sm-12'>
                              {item?.discount_price ?
                                <p>{item?.discount_price} টাকা</p>
                                :
                                <p>{item?.regular_price} টাকা</p>
                              }
                              </div>
                            </div>
                          </li>
                        ))}
                        </ul>
                      </div>
                      :
                      <div className='bg-white table-responsive position-absolute border rounded search-div w-100'>
                        <table className="mb-3 table bordered table-hover w-100 mx-auto">
                          <tbody className='text-center'  style={{width:'100% !important' }}>
                            {searchBooks?.map((item) => (
                              <tr key={item?.id} className='w-100'>
                                <td>
                                  <Link to={`/books/${item.id}`}>
                                    <img src={item?.image} height="40px" alt="" />
                                  </Link>
                                </td>
                                <td className=''>
                                  <Link to={`/books/${item.id}`}>
                                    {item?.english_title}
                                  </Link>
                                </td>
                                {authors?.map((author)=>(
                                  <td key={author?.id}>
                                  {author?.id===item?.author &&
                                    <Link to={`/books/${item.id}`}>
                                      {author?.name}
                                    </Link>
                                  }
                                  </td>
                                ))}                                
                                {item?.discount_price ?
                                <td>{item?.discount_price} টাকা</td>
                                :
                                <td>{item?.regular_price} টাকা</td>
                                }
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    }
                  </>
                }
                </>
              }
              </>
          }
          </div>

          <div className=" d-flex col-12 col-md-5 justify-content-center justify-content-md-end align-items-center ">
            {admin.length!==0 &&
              <Link to="/admin" >
                <i className="fa-solid fa-user icon-circle"></i>
              </Link>
            }
            {
              user?.phone_number ?
              <i className="fa-solid fa-right-from-bracket icon-circle logout-hover"  onClick={logout}/>
              :
              <Link to="/login">
                <i className="fa-solid fa-user-plus icon-circle"></i>
              </Link>
            }
            <div className="cart">
              <Link to="/wishlist">
                <i className="fa-solid fa-heart icon-circle"></i>
                <span>{wishlistLength!==0 && wishlistLength}</span>
              </Link>
            </div>
            <div className="cart">
              <Link to="/cart">
                <i className="fa fa-shopping-bag icon-circle"></i>
                <span>{cartLength!==0 && cartLength}</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Search;