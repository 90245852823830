import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useNovelChild() {
    const [novelChilds, setNovelChilds] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/category/?name=গল্প-উপন্যাস-কবিতা`)
       .then(res => res.json())
       .then(result => {
        setNovelChilds(result[0].children);
       });
     }, []);

return [ novelChilds ];

}