import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory} from 'react-router-dom';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MegaMenu from '../common/MegaMenu/MegaMenu';
import { url } from '../../App';

export default function ResetPassword() {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  
  //location redirect
  const history = useHistory();
  const redirect_uri = '/';

  const onSubmit = data => {

    let token = localStorage.getItem('token');

    fetch(`${url}/reset-password/`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer"+" "+token
      },
      body: JSON.stringify(data),
    })
    .then((res) => res.json())
    .then((result) =>{
        if(result.status===401){
          alert(result.errors);
        }
        else{
          reset();
          history.push(redirect_uri);
        }
    });
  }

  
  return (
    <>
    <Header></Header>
    <MegaMenu></MegaMenu>
    <div className="container p-3 mb-5 row mx-auto">
        <div className="col-sm-12 col-md-12 col-lg-8 mt-3 mx-auto ">
          <p className="text-center h1 fw-bold mb-4 mx-1  mt-4">পাসওয়ার্ড পরিবর্তন</p>
          <form className="container mx-auto w-75 border rounded p-4 border-0 shadow" onSubmit={handleSubmit(onSubmit)} >
            <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ফোন নাম্বার:</label>
              <input
                  {...register("phone_number", {required: true})}
                  type="text"
                  pattern="[0-9]*"
                  placeholder='ফোন নাম্বার লিখুন'
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded fs-6 fw-small"
              />
              {errors.phone_number?.type === 'required' && <p role="alert" className="text-danger "><small>ফোন নাম্বার অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ও.টি.পি:</label>
              <input
                  {...register("otp", {required: true})}
                  type="text"
                  placeholder='ও.টি.পি লিখুন'
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded "
              />
              {errors.otp?.type === 'required' && <p role="alert" className="text-danger "><small>ও.টি.পি অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">পাসওয়ার্ড:</label>
              <input
                  {...register("password", {required: true})}
                  type="password"
                  placeholder='পাসওয়ার্ড লিখুন'
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded fs-6 fw-small"
              />
              {errors.password?.type === 'required' && <p role="alert" className="text-danger "><small>পাসওয়ার্ড অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">কনফার্ম পাসওয়ার্ড:</label>
              <input
                  {...register("password2", {required: true})}
                  type="password"
                  placeholder='কনফার্ম পাসওয়ার্ড লিখুন'
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded fs-6 fw-small"
              />
              {errors.password2?.type === 'required' && <p role="alert" className="text-danger "><small>কনফার্ম পাসওয়ার্ড অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className='d-flex justify-content-center mx-4 mb-3 mb-lg-4'>
              <input
                type="submit"
                value="সাইন ইন"
                className="btn btn-warning border border-warning text-white rounded "
              />
            </div>
          </form>
        </div>
    </div>
    <Footer></Footer>
    </>
  );
}
