import React from 'react';
import { NavLink } from 'react-router-dom';
import image from '../../assets/images/defaultImage.jpeg';


const PublisherPage = ({ publisher }) => {
  return (
    <div className="col">
      <div className="card border-0 shadow h-100 center">
      {!publisher?.image ? 
          <img
          src={image}
          className="card-img-top"
          alt="Publisher"
          /> 
        : 
          <img
          src={publisher?.image}
          className="card-img-top"
          alt="Publisher"
          /> 
        }
        <div className="card-body h-100">
          <h5 className="card-title text-warning"><NavLink to={`/publishers/${publisher.name}`}>{publisher.name}</NavLink></h5>

          <button className=" bg-warning px-2 py-1 shadow text-white rounded">
            
            <NavLink className="text-white" to={`/publishers/${publisher.name}`}>
              Read more
            </NavLink>
          </button>
        </div>
      </div>
    </div>
  );
};
export default PublisherPage;
