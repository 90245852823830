import React, {useState, useEffect} from 'react';
import useLocalCart from '../../../Hooks/useLocalCart';
import useStationarySortByHighPrice from '../../../Hooks/Sorting/SortingStationary/useStationarySortByHighPrice';
import useStationarySortByLowPrice from '../../../Hooks/Sorting/SortingStationary/useStationarySortByLowPrice';
import useStationarySortByNew from '../../../Hooks/Sorting/SortingStationary/useStationarySortByNew';
import useStationarySortByStock from '../../../Hooks/Sorting/SortingStationary/useStationarySortByStock';
import { addToDb } from '../../../Utilities/LocalStorage';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import MegaMenu from '../../common/MegaMenu/MegaMenu';
import Stationary from './Stationary';
import useStationary from '../../../Hooks/useStationary';
import Pagination from '../../Shared/Pagination/Pagination';


export default function Stationaries() {
  const [sorted_items, setSortedItems] = useState([]);
  const [stationaries, pageCount, handlePageClick, offset, displayItems] = useStationary();
  const [sortedNewItems] = useStationarySortByNew();
  const [sortedStockItems] = useStationarySortByStock();
  const [sortedLowItems] = useStationarySortByLowPrice();
  const [sortedHighItems] = useStationarySortByHighPrice();
  const [sortingValue, setSortingValue] = useState('');
  const [CartItem, setCartItem]= useLocalCart(stationaries);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [displayTotalItems, setDisplayTotalItems] = useState([]);
  const [offsetItems, setOffsetItems] = useState(0);


  //pagination page click function
  const handleTotalPageClick=(e)=>{
    const selectedPage = e.selected;
    offsetItems(selectedPage*20);
  }
  
  //sorting function
  const selectSort = (e) =>{
    setSortingValue(e.target.value);
  }

  //default sort function
  const selectDefaultSort = () =>{
    setSortingValue('');
  }

  useEffect(() => {
    let data =[];  

    if(sortingValue === "highest"){
      data = sortedHighItems;
      setSortedItems(data);
    }
    if(sortingValue === "lowest"){
      data = sortedLowItems;
      setSortedItems(data);
  
    }
    if(sortingValue === "inStock"){
      data = sortedStockItems;
      setSortedItems(data);
  
    }
    if(sortingValue === "new"){
      data=sortedNewItems;
      setSortedItems(data);
    }
    
    const pageNumber = (Math.floor(data.length/20))+1;
    setTotalPageCount(pageNumber);
    setDisplayTotalItems(data.slice(offsetItems, (offsetItems+(20*1))));

  }, [sortingValue, offsetItems]);


  // handleAddToCart function
  const handleAddToCart=(product)=>{
    const newCart=[...CartItem, product];
    setCartItem(newCart);
    addToDb(product.id);
    alert('Product addded successfully!');
  }

  return (
    <>
      <Header CartItem={CartItem}></Header>
      <MegaMenu></MegaMenu>
      <h4 className="pages-heading text-center fw-bold">Our Stationaries Items</h4>
      <hr className="bg-success w-25 mx-auto" />
      <div className="container mx-auto m-3 mb-5 ">
        {(displayItems.length===0 && displayTotalItems.length===0) && 
          <div className='vh-100'></div>
        }
        {(displayTotalItems.length!==0 || displayItems.length!==0) && 
          <>
          <section className=''>
            <div className="sort-filter m-3 d-flex justify-content-end">
              <div className='sort'>
                <form action="#" className=''>
                  <label htmlFor='sort'></label>
                  <select name='sort' id='sort' className='sort-selection bg-light p-2 rounded custom-border-outline custom-text fw-medium p-2'  onChange={(e)=>selectSort(e)}>
                      <option value="default"  onClick={selectDefaultSort}>Default Sorting</option>
                      <option value="lowest">Price low to high</option>
                      <option value="highest">Price high to low</option>
                      <option value="inStock">In stock</option>
                      <option value="new">New arrivals</option>
                  </select>
                </form>
              </div>
            </div>
            <div className="">
              {(sortingValue==='' && displayItems?.length!==0) &&
              <>
                {displayItems?.length<=4 ?
                  <div style={{minHeight:'1000px', maxHeight:'2500px'}}>
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                    {displayItems.map((product) => (
                    <Stationary key={product.id} product={product} 
                    handleAddToCart={handleAddToCart}></Stationary>
                    ))}
                    </div>
                  </div>
                :
                  <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                    {displayItems.map((product) => (
                    <Stationary key={product.id} product={product} 
                    handleAddToCart={handleAddToCart}></Stationary>
                    ))}
                  </div>
                }
                <div className='container w-75 mx-auto mt-5'>              
                  <Pagination pageCount={pageCount} handlePageClick={handlePageClick} offset={offset}></Pagination>
                </div>
              </>
              }

              {(sortingValue!=='' && sorted_items?.length!==0) &&
                <>
                  {(sorted_items?.length<=4) ?
                    <div style={{minHeight:'1000px', maxHeight:'2500px'}}>
                      <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalItems.map((product) => (
                        <Stationary key={product.id} product={product} 
                        handleAddToCart={handleAddToCart}></Stationary>
                      ))}
                      </div>
                    </div>
                  :
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-4 mx-auto">
                      {displayTotalItems.map((product) => (
                        <Stationary key={product.id} product={product} 
                        handleAddToCart={handleAddToCart}></Stationary>
                      ))}
                    </div>
                  }
                  <div className='container w-75 mx-auto mt-5'>              
                    <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetItems}></Pagination>
                  </div>
                </>
                }
            </div>
          </section>
          </>
        }
      </div>
      <Footer></Footer>
    </>
  );
}

