import React from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';

function Pagination({pageCount, handlePageClick, offset}) {

    return (
        <div className='page text-center d-flex justify-content-center align-align-items-center paginating-container '>
            <ReactPaginate
                className='paginate'
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                activeClassName="activePage"
                containerClassName={'page'}
                subContainerClassName='page'
                disabledClassName='disabled-button'
                previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>}
                nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>}
            />
        </div>
    );
}

export default Pagination;