import { useState, useEffect } from 'react';
import { getStoredCart } from '../Utilities/LocalStorage';

const useLocalCart = (products) => {
  const [CartItem, setCartItem] = useState(products);

  useEffect(() => {
    if (products.length) {
      const savedCart = getStoredCart();
      const storedCart = [];
      for (const id in savedCart) {
        const addedProduct = products.find((product) => product.id == id);
        if (addedProduct) {
          const quantity = savedCart[id];
          addedProduct.quantity = quantity;
          storedCart.push(addedProduct);
        }
      }
      setCartItem(storedCart);
    }
  }, [products]);
  
  return [CartItem, setCartItem];
};

export default useLocalCart;
