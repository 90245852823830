import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useMedicalChild() {
    const [medicalNursingChilds, setMedicalNursingChilds] = useState([]);

    useEffect(() => {
        fetch(`${url}/api/category/?name=মেডিকেল-নার্সিং`)
       .then(res => res.json())
       .then(result => {
        setMedicalNursingChilds(result[0].children);
       });
    }, []);

return [ medicalNursingChilds ];

}