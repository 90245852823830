import React from 'react';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import '../adminpage.css';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {url} from '../../../App';
import useAllAdmin from '../../../Hooks/useAllAdmin';
import useAllUsers from '../../../Hooks/useAllUsers';

export default function MakeAdmin() {
  const [users] = useAllUsers();
  const [ admins] = useAllAdmin();
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const history = useHistory();

  const onSubmit = data => {
    let phone;

    if(data?.phone_number){
      phone = users.find(user => user?.phone_number === data?.phone_number);

      //if phone_number is already exist then show alert messeage
      if(phone?.phone_number){
        alert("Phone Number is already exist!");
        reset();
      }
      else{
        //post method for make-admin
        fetch(`${url}/makeAdmin/`, {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((result) =>{
          if(result.status===400){
            alert(result.message);
            reset();
          }
          else{
            alert("Admin created Successfully!");
            reset();
            history.push('/make-admin');
          }     
        });
      }      
    }
  }

  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container">
          <AdminNavbar></AdminNavbar>
          <h3 className="update-title pt-3">Admin</h3>
          <hr className="update-title-hr" />
          <div className="border-0 rounded shadow mb-3 bg-white update-form-container col">
            <h4 className="text-center">Create a new Admin</h4>
            <hr className='w-25 mx-auto mb-4'/>
            <form className="container mx-auto w-75 border border-0 shadow rounded p-4" onSubmit={handleSubmit(onSubmit)} >
              <div className="row mb-3 w-100 mx-auto">
                  <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ফোন নাম্বার:</label>
                  <input
                      {...register("phone_number", {required: true})}
                      type="text"
                      pattern="[0-9]*"
                      placeholder='ফোন নাম্বার লিখুন'  className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-success rounded "
                  />
                  {errors.phone_number?.type === 'required' && <p role="alert" className="text-danger "><small>ফোন নাম্বার অত্যাবশ্যকিয়*</small></p>}
              </div>
              <div className="row mb-3 w-100 mx-auto">
                  <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">পাসওয়ার্ড:</label>
                  <input
                      {...register("password", {required: true})}
                      type="text"
                      placeholder='পাসওয়ার্ড লিখুন' className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-success rounded "
                  />
                  {errors.password?.type === 'required' && <p role="alert" className="text-danger "><small>পাসওয়ার্ড অত্যাবশ্যকিয়*</small></p>}
              </div>
              <div className='d-flex justify-content-center mx-4 mb-3 mb-lg-4'>
                  <input
                      type="submit"
                      value="Submit"
                      className="btn btn-success border border-success text-white rounded "
                  />
              </div>
            </form>
          </div>

          <div className="col container w-75 mx-auto p-2 border-0 rounded shadow mb-4 bg-white table-responsive-sm table-responsive-md ">
            <h4 className="update-title ">Admin List</h4>
            <hr className="update-title-hr" />
            <table className=" mb-3 table striped bordered text-center">
              <thead className="">
                <tr className="text-success">
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {admins?.map((admin) => (
                  <tr key={admin?.id}>
                    <td>{admin?.id}</td>
                    <td>{admin?.name}</td>
                    <td>{admin?.phone_number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
