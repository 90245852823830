import {useEffect,useState} from 'react';
import { url } from '../App';

export default function useAllAdmin() {
    const [admins, setAdmins] = useState([]);


    useEffect(() => {
        fetch(`${url}/api/user/?is_admin=true`)
       .then(res => res.json())
       .then(result => {
         setAdmins(result);
       });
    }, []);

return [ admins ];

}

