import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useProducts() {
    const [products, setProducts] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        fetch(`${url}/api/product/`)
       .then(res => res.json())
       .then(result => {
        setProducts(result);
        const pageNumber = Math.ceil(result.length/20);
        setPageCount(pageNumber);
       });
     }, []);

return [ products, pageCount ];

}