import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory} from 'react-router-dom';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MegaMenu from '../common/MegaMenu/MegaMenu';
import { url } from '../../App';
import useAllUsers from '../../Hooks/useAllUsers';

export default function ForgetPassword() {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const [users] = useAllUsers();

  //location redirect
  const history = useHistory();
  const redirect_uri = '/reset-password';

  const onSubmit = data => {
    let phone;

    //generate otp number & saved into data
    let otp = Math.floor(Math.random() * (9999 - 1000)) + 1000;
    data.otp = otp;

    if(data?.phone_number){
      phone = users.find(user => user.phone_number === data?.phone_number);

      //if phone_number is valid then otp messeage will send 
      if(phone?.phone_number){

        //third-party otp url for reset-password
        fetch(`https://24bulksms.com/24bulksms/api/otp-api-sms-send?sender_id=377&api_key=175655169427363520230131091716pmcvWfz5GV&mobile_no=${data.phone_number}&message=Dear Customer, Your OTP for Reset Password at Nilkhet-Boighor is ${otp}&user_email=nilkhetboighor.2020@gmail.com`)
        .then((res)=>res.json())

        //post method for send_reset_otp
        fetch(`${url}/send_reset_otp/`, {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(data),
        })
        .then((res) => res.json())
        .then((result) =>{
          if(result.status===400){
            alert(result.message);
          }
          else{
            localStorage.setItem('token', result.token.access);
            alert("OTP Send Successfully!");
            reset();
            history.push(redirect_uri);
          }
        });
      }
      
      //if phone_number is not valid then show alert messeage
      else{
        alert("Phone Number is not matched!");
        reset();
      }
    }
    
  }

  
  return (
    <>
    <Header></Header>
    <MegaMenu></MegaMenu>
    <div className="container p-3 mb-5 row mx-auto">
      <div className="col-sm-12 col-md-12 col-lg-8 mt-3 mx-auto ">
          <p className="text-center h1 fw-bold mb-4 mx-1  mt-4">পাসওয়ার্ড পরিবর্তন</p>
          <form className="container mx-auto w-75 border rounded p-4 border-0 shadow" onSubmit={handleSubmit(onSubmit)} >

            <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ফোন নাম্বার:</label>
              <input
                  {...register("phone_number", {required: true})}
                  type="text"
                  pattern="[0-9]*"
                  placeholder='ফোন নাম্বার লিখুন' className=" p-1 col-sm-12 col-md-12 col-lg-9 border border-warning rounded fs-6 fw-small"
              />
              {errors.phone_number?.type === 'required' && <p role="alert" className="text-danger "><small>ফোন নাম্বার অত্যাবশ্যকিয়*</small></p>}
            </div>
            <div className='d-flex justify-content-center mx-4 mb-3 mb-lg-4'>
              <input
                type="submit"
                value="পরবর্তী"
                className="btn btn-warning border border-warning text-white rounded "
              />
            </div>
        </form>
      </div>
    </div>
    <Footer></Footer>
    </>
  );
}
