import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useSchoolCollage() {
    const [schoolCollageBooks, setSchoolCollageBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/স্কুল-কলেজ`)
       .then(res => res.json())
       .then(result => {
        setSchoolCollageBooks(result);
       });
    }, []);

return [ schoolCollageBooks ];

}