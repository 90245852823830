import React, {useState} from 'react';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import { NavLink, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import '../adminpage.css';
import {url} from '../../../App';


export default function AdminOrders({orders}) {
  const history = useHistory();
  const [search_input, setSearchInput] = useState('');
  const [searchOrders, setSearchOrders] = useState([]);
  const [result, setResults] = useState(0);
  const {register, handleSubmit} = useForm();


  // order delivered function
  const handleDelivered = (id, order) =>{
    order.status = "Delivered";

    fetch(`${url}/api/order/${id}/`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
    .then((res)=> res.json())
    .then((result) => {
      alert('Status Updateed successfully');
      history.push('/admin-orders');
      window.location.reload();
    });
  }

  // order returned function
  const handleReturned = (id, order) =>{
    order.status = "Returned";

    fetch(`${url}/api/order/${id}/`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
    .then((res)=> res.json())
    .then((result) => {
      alert('Status Updateed successfully');
      history.push('/admin-orders');
      window.location.reload();
    });
  }

  // order canceled function
  const handleCancelled = (id, order) =>{
    order.status = "Cancelled";

    fetch(`${url}/api/order/${id}/`, {
      method: "PUT",
      headers: { 
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
    .then((res)=> res.json())
    .then((result) => {
        
      let message = `Nilkhet Boi Ghor, Dear ${order.name}, Your Order id ${order.id} is cancelled. Hope we will get you soon . Thank You. www.nilkhetboighor.com`

      //third-party url for order placed
      fetch(`https://24bulksms.com/24bulksms/api/otp-api-sms-send?sender_id=377&api_key=175655169427363520230131091716pmcvWfz5GV&mobile_no=${order.phone_number}&message=${message}&user_email=nilkhetboighor.2020@gmail.com`)
      .then((res)=>res.json())

      alert('Status Updateed successfully');
      history.push('/admin-orders');
      window.location.reload();
    });
  }


  // searching function
  const onSearch = data =>{

    setSearchInput(data.search_input);
    if(data.search_input===''){
      setSearchOrders([]);
      setResults(0);
    }
    else{
    fetch(`${url}/api/order/?search=${data.search_input}`)
      .then(res => res.json())
      .then(result => {
        if(result.length===0){
          setResults(1);
        }
        else{
          setResults(2);
          setSearchOrders(result);
        }
      });
    }  
  }

  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container " >
          <AdminNavbar></AdminNavbar>
          <h3 className="text-center pt-3 heading">Orders</h3>
          <hr className=" update-title-hr" />
          {/* search order */}
          <div className="w-50 mx-auto mb-3">
            <form className='container mx-auto' onSubmit={handleSubmit(onSearch)}>
              <div className="row mb-3">
                <div className='col-sm-8 col-md-9 col-lg-9 me-2 border border-success rounded-pill p-1 bg-white'>
                  <i className="fa fa-search text-center p-1"></i>
                  <input
                    {...register("search_input")}
                    type="text"
                    className="fs-6 p-1 w-75"
                    placeholder="Search Order by Customer Phone Number or Name"
                    />
                </div>
                <button className='col-sm-3 col-md-2 col-lg-2 btn btn-outline-success rounded bg-white text-success fw-bold' type='submit' value='submit'>Search</button>
              </div>
            </form>
          </div>
          {/* show search result */}
          {search_input===''?
            <></>
            :
            <>
              {result===0 ?
                <></>
                :
                <>
                {result===1?
                  <div className='container p-2 mb-4 bg-white table-responsive border rounded search-div '>
                    <p className='fw-bold custom-h4 p-2 fs-6'>Sorry, No result found!</p>
                  </div>
                  :
                  <>
                    <div className="container p-2 border-0 rounded shadow mb-5 bg-white table-responsive-sm">
                      <h4 className="text-center heading">Searching List</h4>
                      <hr className="w-25 mx-auto heading" />
                      <table className="mb-3 table striped bordered table-striped table-hover">
                        <thead className="custom-search">
                          <tr className="text-start">
                            <th scope="col">ID</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Phone No.</th>
                            <th scope="col">Total Bill</th>
                            <th scope="col">Status</th>
                            <th scope="col">Update</th>
                          </tr>
                        </thead>
                        <tbody className='custom-search'>
                          {searchOrders.map((order) => (
                            <tr key={order?.id}>
                            <td>{order?.id}</td>
                            <td>
                              <NavLink to={`/order-invoice/${order.id}`}>{order.name}</NavLink>
                            </td>
                            <td>
                              <NavLink to={`/order-invoice/${order.id}`}>{order.phone_number}</NavLink>
                            </td>
                            <td>{order?.total} টাকা</td>
                            <td>{order?.status}</td>
                            <td>
                              {order?.status==='Pending'  &&
                              <>
                                <button onClick={() => handleDelivered(order?.id, order)} className="btn btn-outline-success m-1 btn-sm">Delivered</button>
                                <button onClick={() => handleReturned(order?.id, order)} className="btn btn-outline-warning m-1 btn-sm">Returned</button>
                                <button onClick={() => handleCancelled(order?.id, order)} className="btn btn-outline-danger btn-sm">Cancelled</button>
                              </>
                              }
                              {(order?.status==='Delivered' || order.status==='Returned' || order.status==='Cancelled')  &&
                              <>
                                <button className="btn btn-success m-1 btn-sm" disabled>Delivered</button>
                                <button className="btn btn-warning m-1 btn-sm" disabled>Returned</button>
                                <button className="btn btn-danger btn-sm" disabled>Cancelled</button>
                              </>
                              }
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                }
                </>
              }
            </>
          }
          {/* view all order list */}
          <div className="container p-2 border-0 rounded shadow mb-4 bg-white table-responsive-sm">
            <h4 className="text-center heading"> Orders List</h4>
            <hr className="w-25 mx-auto heading" />
            <table className="mb-3 table striped bordered table-striped table-hover">
              <thead className="custom-tbody">
                <tr className="heading">
                  <th scope="col">ID</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">Phone No.</th>
                  <th scope="col">Total Bill</th>
                  <th scope="col">Status</th>
                  <th scope="col">Update</th>
                </tr>
              </thead>
              <tbody className='custom-tbody'>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>
                      <NavLink to={`/order-invoice/${order.id}`}>{order.name}</NavLink>
                    </td>
                    <td>
                      <NavLink to={`/order-invoice/${order.id}`}>{order.phone_number}</NavLink>
                    </td>
                    <td>{order.total} টাকা</td>
                    <td>{order.status}</td>
                    <td>
                      {order?.status==='Pending'  &&
                      <>
                        <button onClick={() => handleDelivered(order?.id, order)} className="btn btn-outline-success m-1 btn-sm">Delivered</button>
                        <button onClick={() => handleReturned(order?.id, order)} className="btn btn-outline-warning m-1 btn-sm">Returned</button>
                        <button onClick={() => handleCancelled(order?.id, order)} className="btn btn-outline-danger btn-sm">Cancelled</button>
                      </>
                      }
                      {(order?.status==='Delivered' || order.status==='Returned' || order.status==='Cancelled')  &&
                      <>
                        <button className="btn btn-success m-1 btn-sm" disabled>Delivered</button>
                        <button className="btn btn-warning m-1 btn-sm" disabled>Returned</button>
                        <button className="btn btn-danger btn-sm" disabled>Cancelled</button>
                      </>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
