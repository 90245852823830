import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import {url} from '../../../App';

export default function AdminCategories({categories}) {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const {register: searchRegister, handleSubmit: handleSearch} = useForm();
  const [name, setName] = useState('');
  const [searchCategories, setSearchCategories] = useState([]);
  const [result, setResults] = useState(0);
  const history = useHistory();

  // category add function
  const onSubmit = data => {

    fetch(`${url}/api/category/`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())
    .then((result) =>{
      if(result.status===400){
        alert(result.message);
        reset();
      }
      else{
        alert("Category added Successfully!");
        reset();
        history.push('/admin-categories');
      }     
    });
  }

  // category delete function
  const handleDelete = name =>{
    const confirm = window.confirm('Are you sure to delete category?');
    if(confirm){
      fetch(`${url}/category/${name}`, {
        method:'DELETE'
      })
      alert('Category deleted!');
      window.location.reload();
    }
  }

  // searching function
  const onSearch = data =>{

    setName(data.name);
    if(data.name===''){
      setSearchCategories([]);
      setResults(0);
    }
    else{
      fetch(`${url}/api/category/?search=${data.name}`)
        .then(res => res.json())
        .then(result => {
          if(result.length===0){
            setResults(1);
          }
          else{
            setResults(2);
            setSearchCategories(result);
          }
      });
    }  
  }

 
  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container ">
          <AdminNavbar></AdminNavbar>
          <h3 className="text-center pt-3"> Categories</h3>
          <hr className="w-25 mx-auto" />
          {/* search category */}
          <div className="w-50 mx-auto mb-3">
            <form className='container mx-auto' onSubmit={handleSearch(onSearch)}>
              <div className="row mb-3">
                <div className='col-sm-8 col-md-9 col-lg-9 me-2 border border-success rounded-pill p-1 bg-white'>
                  <i className="fa fa-search text-center p-1"></i>
                  <input
                    {...searchRegister('name')}
                    type="text"
                    placeholder="Search category by Name"
                    className="fs-6 p-1 w-75"
                  />
                </div>
                <button className='col-sm-3 col-md-2 col-lg-2 btn btn-outline-success rounded bg-white text-success fw-bold' type='submit' value='submit'>Search</button>
              </div>
            </form>
          </div>
          {/* show search result */}
          {name===''?
            <></>
              :
              <>
              {result===0 ?
                <></>
                :
                <>
                {result===1?
                  <div className='container p-2 mb-4 bg-white table-responsive border rounded search-div '>
                    <p className='fw-bold custom-h4 p-2 fs-6'>Sorry, No result found!</p>
                  </div>
                  :
                  <>
                    <div className="container p-2 border-0 rounded shadow mb-5 bg-white table-responsive-sm">
                      <h4 className="text-center heading">Searching List</h4>
                      <hr className="w-25 mx-auto heading" />
                      <table className="mb-3 table striped bordered table-striped table-hover">
                        <thead className="custom-search">
                          <tr className="heading">
                          <th scope="col">ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Parent ID</th>
                          <th scope="col">Total Children</th>
                          <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody className='custom-search'>
                          {searchCategories.map((category) => (
                            <tr key={category.id}>
                            <td>{category.id}</td>
                            <td>{category.name}</td>
                            <td>{category.parent}</td>
                            <td>{category.children.length}</td>
                            <td>
                              <NavLink to={`/update-category/${category.id}`} className="btn btn-secondary m-1">
                                <i className="fa-solid fa-pen-to-square text-white"></i>
                                </NavLink>
                              <button
                                onClick={() => handleDelete(category?.name)}
                                className="btn btn-danger m-1"
                              >
                                <i className="far fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                }
                </>
              }
            </>
          }
          {/* add a new category */}
          <div className="container p-3 border-0 rounded shadow mx-auto w-75 mb-3 bg-white">
            <h4 className="text-center">Add a new Category</h4>
            <form className="w-50 mx-auto " onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register("name", {required: true})}
                type="text"
                placeholder="ক্যাটাগরীর নাম লিখুন"
                className="p-3 m-3 w-100 border border-success rounded"
              />
              {errors.name?.type === 'required' && <p role="alert" className="text-danger "><small>ক্যাটাগরীর নাম্ অত্যাবশ্যকিয়*</small></p>}
              <br />
              <select className="p-3 m-3 w-100 border border-success rounded bg-white" {...register("parent")}>
              <option selected disabled value="">একটি প্যারেন্ট ক্যাটাগরী
               নির্বাচন করুন</option>
              {categories.map((category) => (
                <option value={category.id} key={category.id}>{category.name}</option>
              ))}
              </select>

              <input
                type="submit"
                value="Submit"
                className="btn btn-success w-100 border border-success rounded m-3"
              />
            </form>
          </div>
          {/* view all categories */}
          <div className="container p-2 border-0 rounded shadow mb-4 bg-white table-responsive-sm table-responsive-md ">
            <h4 className="text-center"> Category List</h4>
            <hr className="w-25 mx-auto" />
            <table className="mb-3 table striped bordered table-striped table-hover">
              <thead  className="custom-tbody">
                <tr className="text-success">
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Parent ID</th>
                  <th scope="col">Total Children</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="custom-tbody">
                {categories?.map((category) => (
                  <tr key={category?.id}>
                    <td>{category?.id}</td>
                    <td>{category?.name}</td>
                    <td>{category?.parent}</td>
                    <td>{category?.children?.length}</td>
                    <td>
                      <NavLink to={`/update-category/${category.id}`} className="btn btn-secondary m-1">
                        <i className="fa-solid fa-pen-to-square text-white"></i>
                        </NavLink>
                      <button
                        onClick={() => handleDelete(category?.name)}
                        className="btn btn-danger m-1"
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
