import React from 'react';
import useUserOrderItems from '../../Hooks/useUserOrderItems';
import OrderItem from './OrderItem';
import './Order.css';


const OrderItems =({order})=> {
  const [orderItems] = useUserOrderItems(order.id);

  return (
    <div className='col'>
      <div className="bg-white table-responsive border rounded mb-3 p-3">
        <div className='row mt-3 mb-3 text-dark'>
          <div className='col col-lg-7 col-md-7 col-sm-5 text-start'>
            <h6 className='heading fw-bold'>Order Info.</h6>
            <h6 className='heading'>Order ID : {order?.id}</h6>
            <h6 className='heading'>Placed : {order?.date}</h6>
            <h6 className='heading'>Delivery Method : {order?.delivery_option}</h6>
            <h6 className='heading'>Payment Method : {order?.payment_option}</h6>
            {order?.payment_option==='বিকাশ পেমেন্ট' &&
              <>
              <h6 className='heading'>Transaction ID : {order?.bkash_transaction_ID}</h6>
              <h6 className='heading'>Transaction Amount : {order?.transaction_amount}</h6>
              </>
            }
            {order?.payment_option==='নগদ পেমেন্ট' &&
              <>
              <h6 className='heading'>Transaction ID : {order?.nagad_transaction_ID}</h6>
              <h6 className='heading'>Transaction Amount : {order?.transaction_amount}</h6>
              </>
            }
            {order?.payment_option==='রকেট পেমেন্ট' &&
              <>
              <h6 className='heading'>Transaction ID : {order?.rocket_transaction_ID}</h6>
              <h6 className='heading'>Transaction Amount : {order?.transaction_amount}</h6>
              </>
            }
            <h6 className='heading'>Status : {order?.status}</h6>
          </div>
          <div className='col col-lg-5 col-md-5 col-sm-7 text-start'>
            <h6 className='heading fw-bold'>Delivery Info.</h6>
            <h6 className='heading'>Name : {order?.name}</h6>
            <h6 className='heading'>Address : {order?.delivery_address}, {order?.upazila_thana}, {order?.district}</h6>
            <h6 className='heading'>Phone : {order?.phone_number}</h6>
            {order?.email && <h6>Email : {order?.email}</h6>}
          </div>
        </div>
        <table className="mb-3 table striped bordered ">
          <thead className="text-white border-bg">
            <tr >
              <th scope="col" className='text-start'>Product</th>
              <th scope="col"  className='text-center'>Quantity</th>
              <th scope="col"  className='text-end'>Unit Price</th>
              <th scope="col"  className='text-end'>Total Price</th>
            </tr>
          </thead>
          <tbody className='text-start'>
            {orderItems.map((orderItem) => (
              <OrderItem key={orderItem.id} orderItem={orderItem}></OrderItem>
            ))}
          </tbody>
          <tfoot className=''>
            <tr className='text-center'>
              <td></td>
              <td></td>
              <td className='text-start heading'>Subtotal</td>
              <td className='text-end heading'>{order?.subtotal} টাকা</td>
            </tr>
            <tr className='text-center'>
              <td></td>
              <td></td>
              <td className='text-start heading'>Delivery Charge</td>
              <td className='text-end heading'>{order?.delivery_charge} টাকা</td>
            </tr>
            <tr className='text-center bg-light'>
              <td></td>
              <td></td>
              <td className='text-start heading fw-bold'>Total</td>
              <td className='text-end heading fw-bold'>{order?.total} টাকা</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default OrderItems;