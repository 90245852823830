import React from 'react';
import { Link } from 'react-router-dom';
import './AdminNavbar.css';

export default function AdminNavbar() {


  return (
    <>
    <nav className="navbar navbar-expand-lg bg-admin-nav ml-0">
      <div className="container" >
        <Link className="navbar-brand admin-nav-text " to="/admin">Dashboard</Link>
        <button className="navbar-toggler bg-white admin-nav-text border-0 " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"><i className="fa-solid fa-bars fs-5"></i></span>
        </button>
      </div>
    </nav>
    </>
  )
}
