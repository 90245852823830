import React from 'react';
import Header from '../common/header/Header';
import MegaMenu from '../common/MegaMenu/MegaMenu';
import Footer from '../common/footer/Footer';
import Home from '../SliderHome/Home';
import PopularCategory from './Popular/PopularCategory';
import BCSBank from './BCSBank/BCSBank';
import MedicalNursing from './MedicalNursing/MedicalNursing';
import Law from './Law/Law';
import Departmental from './Departmental/Departmental';
import Authors from './Authors/Authors';
import Publishers from './Publishers/Publishers';
import CompetitiveExam from './CompetitiveExam/CompetitiveExam';
import Engineering from './Engineering/Engineering';
import BBAMBA from './BBAMBA/BBAMBA';
import EnglishMedium from './EnglishMedium/EnglishMedium';
import SchoolCollege from './SchoolCollege/SchoolCollege';
import ProgrammingFreelancing from './ProgrammingFreelancing/ProgrammingFreelancing';
import Islamic from './Islamic/Islamic';
import NovelFictionPoetry from './NovelFictionPoetry/NovelFictionPoetry';
import useLocalCart from '../../Hooks/useLocalCart';
import useLocalWishlist from '../../Hooks/useLocalWishlist';
import useProducts from '../../Hooks/useProducts';
import { addToDb, addToWishlist } from '../../Utilities/LocalStorage';
import useBBAMBA from '../../Hooks/SubCategoryBooks/useBBAMBA';
import useBCSBank from '../../Hooks/SubCategoryBooks/useBCSBank';
import useCompetitiveExam from '../../Hooks/SubCategoryBooks/useCompetitiveExam';
import useDepartmental from '../../Hooks/SubCategoryBooks/useDepartmental';
import useEngineering from '../../Hooks/SubCategoryBooks/useEngineering';
import useEnglishMedium from '../../Hooks/SubCategoryBooks/useEnglishMedium';
import useIslamic from '../../Hooks/SubCategoryBooks/useIslamic';
import useLaw from '../../Hooks/SubCategoryBooks/useLaw';
import useMedicalNursing from '../../Hooks/SubCategoryBooks/useMedicalNursing';
import useNovelPoetry from '../../Hooks/SubCategoryBooks/useNovelPoetry';
import useProgramming from '../../Hooks/SubCategoryBooks/useProgramming';
import useSchoolCollage from '../../Hooks/SubCategoryBooks/useSchoolCollage';

const Shop = ({authors, publishers}) => {
  const [bbaMbaBooks] = useBBAMBA([]);
  const [bcsBooks] = useBCSBank([]);
  const [competitiveBooks] = useCompetitiveExam([]);
  const [departmentalBooks] = useDepartmental([]);
  const [engineeringBooks] = useEngineering([]);
  const [englishMediumBooks] = useEnglishMedium([]);
  const [islamicBooks] = useIslamic([]);
  const [lawBooks] = useLaw([]);
  const [medicalNursingBooks] = useMedicalNursing([]);
  const [novelPoetryBooks] = useNovelPoetry([]);
  const [programmingBooks] = useProgramming([]);
  const [schoolCollageBooks] = useSchoolCollage([]);
  const [ products ]= useProducts();
  const [CartItem, setCartItem]= useLocalCart(products);
  const [WishlistItem, setWishlistItem] = useLocalWishlist(products);

  //cart function
  const handleAddToCart = (book) => {
    const newCart = [...CartItem, book];
    setCartItem(newCart);
    addToDb(book.id);
    alert('Successfully added!');
  };

  //wishlist function
  const handleAddToWishlist = (book) => {
    const existingWishlist = WishlistItem.filter(
      (product) => product.id === book.id
    );
    if(existingWishlist.length===0){
      const newWishlist = [...WishlistItem, book];
      setWishlistItem(newWishlist);
      addToWishlist(book.id);
      alert('Successfully added!');
    }
    else{
      alert('Already in wishlist!');
    }
  };

  return (
    <>
      <Header CartItem={CartItem} WishlistItem={WishlistItem}></Header>
      <MegaMenu ></MegaMenu>
      <Home CartItem={CartItem} WishlistItem={WishlistItem}/>
      <PopularCategory />
      <BCSBank bcsBooks={bcsBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist}/>
      <CompetitiveExam competitiveBooks={competitiveBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist}/>
      <MedicalNursing medicalNursingBooks={medicalNursingBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist}/>
      <Engineering engineeringBooks={engineeringBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist}/>
      <Law lawBooks={lawBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <BBAMBA bbaMbaBooks={bbaMbaBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <Departmental departmentalBooks={departmentalBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <EnglishMedium englishMediumBooks={englishMediumBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <SchoolCollege schoolCollageBooks={schoolCollageBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <ProgrammingFreelancing programmingBooks={programmingBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <Islamic islamicBooks={islamicBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <NovelFictionPoetry novelPoetryBooks={novelPoetryBooks} handleAddToCart={handleAddToCart}  handleAddToWishlist={handleAddToWishlist} />
      <Authors authors={authors}/>
      <Publishers publishers={publishers}/>
      <Footer></Footer>
    </>
  );
};

export default Shop;
