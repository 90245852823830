import React from 'react';
import { NavLink , Link} from 'react-router-dom';
import useLocalCart from '../../../Hooks/useLocalCart';
import { addToDb, removeFromDb } from '../../../Utilities/LocalStorage';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './cart.css';

const Cart = ({ products }) => {
  const [CartItem, setCartItem] = useLocalCart(products);
  const user = JSON.parse(localStorage.getItem('user'));

  //handleAddToCart function
  const handleAddToCart = (product) => {
    const previousProduct = (CartItem.filter((item)=> item.id !== product.id));
    let newProduct = (CartItem.filter((item)=> item.id === product.id));
    newProduct[0].quantity = newProduct[0]?.quantity+1;
    const newCart=[...newProduct, ...previousProduct];
    setCartItem(newCart);
    addToDb(product.id);
    window.alert('Successfully added!');
  };

  // handleRemove function
  const handleRemove = (id) => {
    let newCart=[];
    const previousProduct = (CartItem.filter((item)=> item.id !== id));
    let newProduct = (CartItem.filter((item)=> item.id === id));

    if(newProduct[0]?.quantity>1){
      newProduct[0].quantity = newProduct[0]?.quantity-1;
      newCart=[...previousProduct, ...newProduct];
    }
    else{
      newCart=[...previousProduct];
    }
    
    setCartItem(newCart);
    removeFromDb(id);
    window.alert('Successfully removed!');
  };

  //counting total_price
  const totalPrice = CartItem.reduce(
    (price, item) => price + item.quantity * (item?.discount_price || item.regular_price), 0
  );

  return (
    <>
      <Header CartItem={CartItem}></Header>
      <section className="cart-items container bg-white h-100">
        <div className="row mx-auto row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
        {CartItem?.length === 0 ? 
          <div className='mx-auto container'><h1 className="no-items product heading fw-bold text-center mb-5">আপনার কার্টে কোন আইটেম পাওয়া যায়নি !</h1></div>
          :
          <>
            <div className="col ">
              <div className="container w-100 mx-auto cart-items">
                <div className="border-0 rounded mb-4 bg-white table-responsive w-100 mx-0 ">
                  <table className="mb-3 table text-center table-striped table-hover">
                    <thead className="">
                      <tr className="heading">
                        <th scope="col">ছবি</th>
                        <th scope="col">প্রোডাক্ট</th>
                        <th scope="col">মূল্য</th>
                        <th scope="col">পরিমাণ</th>
                        <th scope="col">মোট</th>
                        <th scope="col">যুক্ত/ডিলিট</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CartItem?.map((item) => {
                        return(
                          <tr key={item.id}>
                            <td>
                              <img src={item?.image} height="70px" alt="" />
                            </td>
                            {item?.type === 'books' && 
                            <td>
                              <NavLink to={`/books/${item.id}`}>
                                {item?.english_title}
                              </NavLink>
                            </td>
                            }
                            {item?.type === 'electronics' && 
                            <td>
                              <NavLink to={`/electronics/${item.id}`}>
                                {item?.english_title}
                              </NavLink>
                            </td>
                            }
                            {item?.type === 'stationary' && 
                            <td>
                              <NavLink to={`/stationary/${item.id}`}>
                                {item?.english_title}
                              </NavLink>
                            </td>
                            }
                            <td>
                              {item?.discount_price || item?.regular_price}
                            </td>
                            <td>{item?.quantity}</td>
                            {item?.discount_price ? 
                              <td>{item.discount_price * item.quantity} টাকা</td>
                              :
                              <td>{item.regular_price * item.quantity} টাকা</td>
                            }
                            {item?.quantity<item.units_stock ? 
                            <td>
                              <button
                                className="btn btn-outline-warning m-1"
                                onClick={() => handleAddToCart(item)}
                              >
                              <i className="fa-solid fa-plus"></i>
                              </button>
                              <button
                                className="btn btn-outline-secondary m-1"
                                onClick={() => handleRemove(item.id)}
                              >
                              <i className="fa-solid fa-minus"></i>
                              </button>
                            </td>
                            :
                            <td>
                              <button
                                className="btn btn-outline-warning m-1 "
                                onClick={()=> alert('Sorry product limit exceed!')}
                              >
                              <i className="fa-solid fa-plus"></i>
                              </button>
                              <button
                                className="btn btn-outline-secondary m-1"
                                onClick={() => handleRemove(item.id)}
                              >
                              <i className="fa-solid fa-minus"></i>
                              </button>
                            </td>
                            }
                          </tr>
                      )})}
                    </tbody>
                  </table>
                </div>
              </div> 
            </div>
            <div className=" col ">
                <div className='card w-100 mx-auto'>
                  <div className='card-body'>
                    <h2 className='card-header fw-bold fs-5 text-white text-center mb-4 border-bg'>অর্ডার সামারী</h2>
                    <div className=" d_flex ">
                      <p className='card-text fw-bold fs-6 heading'>প্রোডাক্টের মূল্য :</p>
                      {!totalPrice ? <p className='card-text fs-6'>0.00 টাকা</p>
                      :
                      <p className='card-text fs-6'>{totalPrice} টাকা</p>
                      }
                    </div>
                    <div className=" d_flex ">
                      <p className='card-text fw-bold fs-6 heading'>পরিবহন চার্জ</p>
                      {!totalPrice ? <p className='card-text fs-6'>0.00 টাকা</p>
                      :
                      <p className='card-text fs-6'>60 টাকা</p>
                      }
                    </div>
                  </div>
                  <div className='justify-content-end align-items-center card-footer bg-white border-0'>
                  {
                    user?.phone_number ?
                    <button type="button" className="px-4 py-2 rounded  checkout  ">
                      <Link className="text-white" to="/checkout" >চেকআউট করুন</Link>
                    </button>
                    :
                    <button type="button" className="px-4 py-2 rounded checkout  ">
                      <Link className="text-white" to={{ pathname: "/register", state: { from: '/checkout' }}}>চেকআউট করুন</Link>
                    </button>
                  }
                  </div>
                </div>
            </div>
          </>
        }
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default Cart;
