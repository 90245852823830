import React from 'react';

export default function AdminBody({books, users, customers, admins, totalPublisher, totalAuthor, totalCategory, totalOrder}) {
 
  return (
    <>
    <div className="container mx-auto my-5 ">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-5 ">
        <div className="col">
          <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-primary">
            <div className=' p-3 fs-3 border rounded-circle text-primary'> <i className="fa-solid fa-users"></i></div>
            <div className="">
              <h5 className="text-center text-primary">{users?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total users</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-primary">
            <div className=' p-3 fs-3 border rounded-circle text-primary'> <i className="fa-solid fa-users"></i></div>
            <div className="">
              <h5 className="text-center text-primary">{admins?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total Admins</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-primary">
            <div className=' p-3 fs-3 border rounded-circle text-primary'> <i className="fa-solid fa-users"></i></div>
            <div className="">
              <h5 className="text-center text-primary">{customers?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total Customers</p>
            </div>
          </div>
        </div>
        <div className="col">
        <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-warning">
        <div className=' p-3 fs-3 border rounded-circle text-warning'> <i className="fa-solid fa-book-open"></i></div>
            <div className="">
              <h5 className="text-center text-warning">{books?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total books</p>
              
            </div>
          </div>
        </div>
        <div className="col">
        <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-danger">
        <div className=' p-3 fs-3 border rounded-circle text-danger'><i className="fa-solid fa-cart-shopping"></i></div>
            <div className="">
              <h5 className="text-center text-danger">{totalOrder?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total orders</p>
              
            </div>
          </div>
        </div>
        <div className="col">
        <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-success">
        <div className=' p-3 fs-3 border rounded-circle text-success'><i className="fa-solid fa-bars"></i></div>
            <div className="">
              <h5 className="text-center text-success">{totalCategory?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total categories</p>
              
            </div>
          </div>
        </div>
        <div className="col">
        <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-warning">
        <div className=' p-3 fs-3 border rounded-circle text-warning'><i className="fa-solid fa-pen-nib"></i></div>
            <div className="">
              <h5 className="text-center text-warning">{totalAuthor?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total authors</p>
              
            </div>
          </div>
        </div>
        <div className="col">
        <div className="border-0 p-5 rounded shadow bg-white d-flex justify-content-between align-items-center border-info">
        <div className=' p-3 fs-3 border rounded-circle text-info'><i className="fa-solid fa-bullhorn"></i></div>
            <div className="">
              <h5 className="text-center text-info">{totalPublisher?.length}</h5>
              <p className='text-center fw-bold text-secondary'>Total Publishers</p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
