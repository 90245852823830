import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useAllCustomers() {
    const [customers, setCustomers] = useState([]);


    useEffect(() => {
        fetch(`${url}/api/user/?is_admin=false`)
       .then(res => res.json())
       .then(result => {
         setCustomers(result);
       });
     }, []);

return [ customers];

}

