import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, Navigation} from 'swiper';
import useCategories from '../../../Hooks/useCategories';


const PopularCategory = () => {
  const [displayData] = useCategories();

  return (
    <>
      <section className=" PopularCategory ">
        <div className='container '>
          <div className="d-flex justify-content-between">
            <div className="d-flex ">
              <i className="fa-solid fa-border-all text-warning custom-icon"></i>
              <h4 className='text-center custom-h4'>জনপ্রিয় ক্যাটাগরী</h4>
            </div> 
            <div className=" ">
              <span><NavLink to={'/categories'}>সবগুলো দেখুন</NavLink></span>
              <i className="fa-solid fa-caret-right custom-view-icon"></i>
            </div>
          </div >
          <div className="">
            <Swiper
                slidesPerView={6}
                grabCursor={true}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[ Autoplay, Navigation]}
                breakpoints= {{
                  360: {
                    slidesPerView: 1
                  },
                  375: {
                    slidesPerView: 1
                  },
                  380: {
                    slidesPerView: 1
                  },
                  400:{
                    slidesPerView:1
                  },
                  412: {
                    slidesPerView: 1
                  },
                  414: {
                    slidesPerView: 1
                  },
                  428: {
                    slidesPerView: 1
                  },
                  640: {
                    slidesPerView: 2
                  },
                  768: {
                    slidesPerView: 3
                  },
                  1024: {
                    slidesPerView: 6
                  }
                }}
                className="mySwiper  mx-auto"
              >
              {displayData.map((category) => (
                <SwiperSlide key={category.id}  className="card border-1 mx-md-1 mx-lg-1 custom-popular-card">
                  <div className='card-body'>
                    <a href={`/category/${category.name}`}>
                      <img
                        className=" card-img-top mx-auto"
                        src='./images/SlideCard/slide-2.png'
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="card-footer bg-white border-0 ">
                    <p className="card-title">
                      <NavLink to={`/category/${category.name}`} className='card-title'>{category.name.slice(0,15)}...</NavLink>
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularCategory;
