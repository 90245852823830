import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useElectronicCategory() {
    const [electronicCategory, setElectronicCategory] = useState({});

    useEffect(() => {
        fetch(`${url}/api/category/?search=ইলেক্ট্রনিক্স`)
       .then(res => res.json())
       .then(result => {
        setElectronicCategory(result[0]);
       });
    },[]);   

return [electronicCategory] ;
}
