import React from 'react';
import { NavLink } from 'react-router-dom';

const Electronic = ({ product, handleAddToCart }) => {
  return (
    <div className="col">
      <div className="card  h-100">
        <a href={`/electronics/${product.id}`}>
          <img className="card-img-top image-watermark" height="200px" src={product?.image} alt={product?.alt_text}/>
        </a>
        <div className="card-body h-100">
          <h5 className="card-title text-warning"><NavLink to={`/electronics/${product.id}`}>{product?.english_title}</NavLink></h5>
          {(product?.discount_price===0 || product?.discount_price===null) ?  <p className="card-text text-start text-dark">{product?.regular_price} টাকা</p>
          :
          <div className="d-inline-flex ">
            <div className="me-2">
              <p className="card-text text-start text-dark text-decoration-line-through">{product?.regular_price} টাকা</p>
            </div>
            <div className="ms-2">
              <p className="card-text text-start text-danger">{product?.discount_price} টাকা</p>
            </div>
          </div>
          } 
        </div>
        {(product?.units_stock <=10 && product?.units_stock >=1) &&
              <div className="text-center"><strong className='text-primary'>*** Last {product?.units_stock}pcs in stock! ***</strong></div>
        }
        <div className="card-footer bg-white border-0">
            <button className="btn col-12 rounded readmore my-1">
            <NavLink className="text-white fs-6" to={`/electronics/${product.id}`} >
              Read more
            </NavLink>
            </button>
            {product?.units_stock!==0 ?
            <button className="btn col-12 rounded addtocart" onClick={() => handleAddToCart(product)}>
              <span className='text-white fs-6'>Add to cart <i className="fa fa-plus"></i></span>
            </button>
            :
            <button className="btn col-12 rounded addtocart disabled">
                <span className='text-white fs-6'>Stock out!</span>
            </button>
            }
        </div>
      </div>
    </div>
  );
};
export default Electronic;
