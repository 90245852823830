import {useEffect,useState} from 'react';
import {url} from '../App';


export default function useStationaryCategory() {
    const [stationaryCategory, setStationaryCategory] = useState({});

    useEffect(() => {
        fetch(`${url}/api/category/?search=স্টেশনারী`)
       .then(res => res.json())
       .then(result => {
        setStationaryCategory(result[0]);
       });
    },[]);   

return [stationaryCategory] ;
}
