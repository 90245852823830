import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useLaw() {
    const [lawBooks, setLawBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/আইন`)
       .then(res => res.json())
       .then(result => {
        setLawBooks(result);
       });
    }, []);

return [ lawBooks ];

}