import React from 'react';
import { NavLink } from 'react-router-dom';

const Category = ({category}) => {
    return (
    <div className="col">
      <div className="card border-0 shadow h-100 center">
        <i className="fa-solid fa-book-open mx-auto fs-1 p-2 custom-text"></i> 
        <div className="card-body h-100 text-center">
          <h5 className="card-title text-warning "><NavLink to={`/category/${category.name}`}>{category?.name}</NavLink></h5>
          <button className=" bg-warning px-2 py-1 shadow text-white rounded ">
            <NavLink className="custom-text" to={`/category/${category.name}`}>
              View All Books
            </NavLink>
          </button>
        </div>
      </div>
    </div>
    );
}

export default Category;