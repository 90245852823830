import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import './Banner.css';
import "swiper/css/navigation";
import {Autoplay, Navigation} from 'swiper';
import useBanners from "../../Hooks/useBanners";

const Banners=()=> {
    const [banners] = useBanners();
   
    return (
        <>
        <section className="container">
            <Swiper
                slidesPerView={1}
                grabCursor={true}
                loop={true}
                autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                }}
                navigation={true}
                modules={[ Autoplay, Navigation]}
                breakpoints= {{
                380: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 1
                },
                1024: {
                    slidesPerView: 1
                }
                }}
                className="mySwiper"
            >
            {banners.map((banner) => {
                return (
                <>
                    <SwiperSlide key={banner.id}>
                        <div>
                        {!banner?.url ? 
                            <img
                            className=" banner-image "
                            src={banner.image}
                            alt={banner.alt_text}
                            />
                        :
                        <a href={banner?.url}>
                            <img
                            className=" banner-image "
                            src={banner.image}
                            alt={banner.alt_text}
                            />
                        </a>
                        }
                        </div>
                    </SwiperSlide>
                </>
                );
            })}
            </Swiper>
        </section>
        </>
    );
}

export default Banners;