import React from 'react';
import { NavLink } from 'react-router-dom';
import image from '../../assets/images/defaultImage.jpeg';

const AuthorPage = ({ author }) => {
  return (
    <div className="col">
      <div className="card border-0 shadow h-100 center">
        {!author?.image ? 
          <img
          src={image}
          className="card-img-top"
          alt="Author"
          /> 
        : 
          <img
          src={author.image}
          className="card-img-top"
          alt="Author"
          /> 
        }
        <div className="card-body h-100">
          <h5 className="card-title text-warning"><NavLink to={`/authors/${author.name}`}>{author.name}</NavLink></h5>

          <button className=" bg-warning px-2 py-1 shadow text-white rounded">
            
          <NavLink className="text-white" to={`/authors/${author.name}`}>
            Read more
          </NavLink>
          </button>
        </div>
      </div>
    </div>
  );
};
export default AuthorPage;
