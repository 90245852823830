import React, {useState} from 'react';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import SideBar from '../SideBar/SideBar';
import '../adminpage.css';
import useBanners from '../../../Hooks/useBanners';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {url} from '../../../App';

export default function AdminBanner() {
  const [ banners] = useBanners();
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const history = useHistory();
  const [ selectedImage,setSelectedImage ] = useState(null);


  //Banner add function
   const onSubmit = data => {
     let formData = new FormData();
     formData.append('image', selectedImage);
     formData.append('alt_text', data.alt_text);
       
     fetch(`${url}/create-banner/`, {
       method: "POST",
       body: formData,
     })
     .then(res => {
       if (!res.ok) throw res;
       else return res.json();
     })
     .then(getData => {
       alert("Banner added Successfully!");
       reset();
       history.push('/admin-banner');  
     })
     .catch(err => {
       err.json().then(text => {
         if (text?.error) {
           console.log(text?.error);
           return;
         }
     })
         console.log(err.statusText);
     });
   }

  // Banner delete function
  const handleDelete = id =>{
    const confirm = window.confirm('Are you sure to delete banner?');
    if(confirm){
        fetch(`${url}/api/banner/${id}`, {
           method:'DELETE'
        })
        alert('Category deleted!');
        window.location.reload();
    }
}

  return (
    <>
      <div className="adminpage m-0 bg-light">
        <SideBar></SideBar>
        <div className="adminpage-container ">
          <AdminNavbar></AdminNavbar>
          <h3 className="update-title pt-3"> Banners</h3>
          <hr className="update-title-hr" />
          <div className="border-0 rounded shadow mb-3 bg-white update-form-container">
            <h4 className="text-center">Add a new Banner</h4>
            <hr className='w-25 mx-auto mb-4'/>
            <form className="form  "  onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <label className='col-sm-12 text-dark fs-6'>*Banner Image:</label>
                <input
                {...register("image", {required: true})}
                type="file"
                id="choose-file"
                placeholder="Upload a banner"
                className="col-sm-12 p-3 m-2 w-100 border border-success rounded"
                data-max-file-size="2M"
                name="upload"
                accept="image/*"
                onChange={(event) => {
                setSelectedImage(event.target.files[0]);
                }}
                />
                {errors.image?.type === 'required' && <p role="alert" className="text-danger "><small>Banner image required*</small></p>}
              </div>
              <br />
              <div className="row">
                <label className=' col-sm-12 text-dark fs-6'>Alt-Text:</label>
                <input
                  {...register("alt_text")}
                  type="text"
                  placeholder="Alterante text"
                  className="col-sm-12 p-3 m-2 w-100 border border-success rounded "
                />
              </div>
              <br/>
              <div className="row">
                <label className=' col-sm-12 text-dark fs-6'>URL:</label>
                <input
                  {...register("url")}
                  type="text"
                  placeholder="URL "
                  className="col-sm-12 p-3 m-2 w-100 border border-success rounded "
                />
              </div>

              <div className='row'>
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-success w-100 p-3 m-2 border border-success rounded fw-bold"
                />
              </div>
            </form>
          </div>
          <div className="container p-2 border-0 rounded shadow mb-4 bg-white table-responsive-sm table-responsive-md ">
            <h4 className="update-title "> Banner List</h4>
            <hr className="update-title-hr" />
            <table className="mb-3 table striped bordered table-striped table-hover">
              <thead className="custom-tbody">
                <tr className="text-success">
                  <th scope="col">ID</th>
                  <th scope="col">Banner</th>
                  <th scope="col">URL</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody className="custom-tbody">
                {banners.map((banner) => (
                  <tr key={banner.id}>
                    <td>{banner.id}</td>
                    <td>
                      <img src={banner.image} height="50px" width="100px" alt={banner.alt_text} />
                    </td>
                    <td>{banner.url}</td>
                    <td>
                      <button
                        onClick={() => handleDelete(banner?.id)}
                        className="btn btn-danger"
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
