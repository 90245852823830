import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useBCSBank() {
    const [bcsBooks, setBcsBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/বিসিএস-ব্যাংক`)
       .then(res => res.json())
       .then(result => {
          setBcsBooks(result);
       });
    }, []);

return [ bcsBooks ];

}