import {useEffect,useState} from 'react';
import {url} from '../../App';

export default function useBooksSortByLowPrice() {
    const [sortedLowBooks, setSortedBooks] = useState([]);


    useEffect(() => {
        fetch(`${url}/all-product/books/?ordering=regular_price`)
       .then(res => res.json())
       .then(result => {
        setSortedBooks(result);
       });
    }, []);

return [ sortedLowBooks ];

}