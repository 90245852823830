import React from 'react';
import { NavLink } from 'react-router-dom';
import './MegaMenu.css';
import useCompetitiveChild from '../../../Hooks/SubCategory/useCompetitiveChild';
import useBCSChild from '../../../Hooks/SubCategory/useBCSChild';
import useMedicalChild from '../../../Hooks/SubCategory/useMedicalChild';
import useEngineeringChild from '../../../Hooks/SubCategory/useEngineeringChild';
import useLawChild from '../../../Hooks/SubCategory/useLawChild';
import useBBAMBAChild from '../../../Hooks/SubCategory/useBBAMBAChild';
import useEnglishMediumChild from '../../../Hooks/SubCategory/useEnglishMediumChild';
import useNovelChild from '../../../Hooks/SubCategory/useNovelChild';

const MegaMenu = () => {
  const [ bcsChilds ] = useBCSChild();
  const [ competitiveChilds ] = useCompetitiveChild();
  const [ medicalNursingChilds ] = useMedicalChild();
  const [ engineeringChilds ] = useEngineeringChild();
  const [ lawChilds ] = useLawChild();
  const [ bbaMbaChilds ] = useBBAMBAChild();
  const [ novelChilds ] = useNovelChild();
  const [ englishMediumChilds ] = useEnglishMediumChild();


  return (
    <div className="container shadow p-1 border-0 rounded megaMenu mb-3">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="/booksnavbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav mx-auto mb-2  mb-lg-0">
              {/* BCS-Bank Category start */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                  className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  বিসিএস-ব্যাংক
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                      <div className="row justify-content-center w-75 mx-auto p-1">
                        {bcsChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                            <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                              <span className="list-group-item  text-start">{child.name}</span>
                            </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* competetive */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  কম্পিটিটিভ-এক্সাম
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {competitiveChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* medical-nursing */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  মেডিকেল-নার্সিং
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {medicalNursingChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* Engineering */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  ইঞ্জিনিয়ারিং
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {engineeringChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* law */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  আইন
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {lawChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* bba-mba */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  বিবিএ-এমবিএ
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {bbaMbaChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* novel */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  গল্প-উপন্যাস-কবিতা
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {novelChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* english-medium */}
              <li className="nav-item dropdown dropdown-mega position-static mx-1">
                <a
                   className="nav-link dropdown-toggle text-dark fw-medium "
                  href="/books"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  ইংলিশ-মিডিয়াম
                </a>
                <div className="dropdown-menu shadow">
                  <div className="mega-content px-md-4">
                    <div className="container ">
                    <div className="row justify-content-center w-75 mx-auto p-1">
                        {englishMediumChilds.map((child) => (
                          <>
                          <div className="col-lg-3 col-sm-12  ">
                          <div className=" list-group">
                          <NavLink className=" fw-medium " to={`/category/${child.name}`}>
                                <span className="list-group-item  text-start">{child.name}</span>
                              </NavLink>
                          </div>
                        </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );

};

export default MegaMenu;
