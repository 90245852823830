import {useEffect,useState} from 'react';
import {url} from '../../../App';


export default function usePublisherBooksSortByStock(publisherName) {
    const [sortedStockBooks, setSortedBooks] = useState([]);

    useEffect(() => {
      if(!publisherName){
        setSortedBooks([]);
      }
      else{
        fetch(`${url}/publisher-book/${publisherName}/?ordering=-units_stock`)
       .then(res => res.json())
       .then(result => {
        setSortedBooks(result);
       });
      } 
    }, [publisherName]);

return [ sortedStockBooks ];

}