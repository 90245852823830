import React, {useEffect,useState} from 'react';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import {useParams} from 'react-router';
import useCategoryByID from '../../../Hooks/CallByID/useCategoryByID';
import useLocalCart from '../../../Hooks/useLocalCart';
import { addToDb, addToWishlist } from '../../../Utilities/LocalStorage';
import useLocalWishlist from '../../../Hooks/useLocalWishlist';
import useStationary from '../../../Hooks/useStationary';
import { url } from '../../../App';
import { Tab, Tabs } from 'react-bootstrap';

export default function StationaryDetail() {
    const [stationaries ] = useStationary();
    const {productID} = useParams();
    const [details, setDetails]= useState([]);
  
    // collecting product details
    useEffect(() => {
        fetch(`${url}/api/product/${productID}/`)
        .then(res => res.json())
        .then(result => {
          setDetails(result)
        });
    }, [productID]);

    const [singleCategory] = useCategoryByID(details?.category);
    const [CartItem, setCartItem]= useLocalCart(details);
    const [WishlistItem, setWishlistItem] = useLocalWishlist(stationaries);


    // handleAddToCart function
    const handleAddToCart=(details)=>{
        const newCart=[...CartItem, details];
        setCartItem(newCart);
        addToDb(productID);
        alert('Product addded successfully!');
    }
    
    // handleAddedToWishlist function
    const handleAddToWishlist = (details) => {
        const existingWishlist = WishlistItem.find(
        (product) => product.id == details.id
        );
        if (!existingWishlist) {
            const newWishlist = [...WishlistItem, details];
            setWishlistItem(newWishlist);
            addToWishlist(productID);
            alert('Successfully added!');
        }
        else{
            alert('Already in wishlist!');
        }
    };

  return (
    <>
      <Header CartItem={CartItem}></Header>
      <div className="container  mt-5 mb-5 ">
        {/* product info. */}
        <div className=" row g-4 w-100 mx-auto border-0 rounded shadow p-4">
          <div className="col-lg-4 col-sm-12 pt-3  text-center ">
            <img
            className="image-watermark"
              src={details?.image}
              width="70%"
              height="85%"
              alt={details?.alt_text}
            />
          </div>
          <div className="col-lg-8 col-sm-12 pt-3 ">
            <h3 className=''>{details?.english_title}</h3>
            <div className="d-flex justify-content-start align-items-center  mx-0">
              {(details?.discount_price===0 || details?.discount_price===null) ?  <h6 className="card-text text-start text-dark fw-bold">{details?.regular_price} টাকা</h6>
              :
              <div className="d-inline-flex ">
                <div className="m-2">
                  <h6 className="card-text text-start text-dark text-decoration-line-through fw-bold">{details?.regular_price} টাকা</h6>
                </div>
                <div className="m-2">
                  <h6 className="card-text text-start text-danger fw-bold">{details?.discount_price} টাকা</h6>
                </div>
              </div>
              }
              {details?.pdf && <button className=" fs-6 btn btn-outline-success  rounded-pill py-1 px-3 mx-3  ">
                Click to read
              </button>} 
            </div>
            {(details?.units_stock <=10 && details?.units_stock >=1) &&
              <div><strong className='text-primary'>*** Last {details?.units_stock}pcs in stock! ***</strong></div>
            }
            <div className='my-2 mx-0'>
              {details?.units_stock!==0 ?
                <button className="px-3 py-1 btn btn-success rounded mx-1 my-1" onClick={() => handleAddToCart(details)}>
                  Add to cart <i className="fa fa-shopping-bag"></i>
                </button>
                :
                <button className="px-3 py-1 btn-secondary rounded mx-1 disabled">
                Stock out!
                </button>
              }
              <button
              id='wishlistbtn'
              className="px-3 py-1 btn btn-outline-success rounded mx-1 my-1"
              onClick={() => handleAddToWishlist(details)}
              >Add to wishlist <i className="fa-regular fa-heart"></i>
              </button>
            </div>
          </div>
        </div>

        {/* product specification & description */}
        <Tabs defaultActiveKey="specification" id="uncontrolled-tab-example" className='mt-5'>
          <Tab eventKey="specification" title="Specification">
            <table className="table table-striped w-100 mx-auto mt-4">
              <tbody className="border border-top ">
                <tr className="mx-auto">
                  <td className="fw-bold text-start ">English Title</td>
                  <td>{details?.english_title}</td>
                </tr>
                <tr className="mx-auto">
                  <td className="fw-bold text-start ">Bangla Title</td>
                  <td>{details?.bangla_title}</td>
                </tr>
                <tr>
                  <td className="fw-bold text-start">Category</td>
                  <td>{singleCategory?.name}</td>
                </tr>
                <tr>
                  <td className="fw-bold text-start">Brand</td>
                  <td>{details?.brand}</td>
                </tr>
                <tr>
                  <td className="fw-bold text-start">Model</td>
                  <td>{details?.model}</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="description" title="Description">
            <div className='container mt-3 p-2'>
              <p className='text-secondary fw-bold'>{details?.description}</p>
            </div>
          </Tab>
        </Tabs>
      </div>
    <Footer></Footer>
    </>
  );
}
