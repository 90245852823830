import './SideBar.css';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {url} from '../../../App';

const SideBar = () => {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));

// logout function
  const logout = () =>{
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    fetch(`${url}/logout/`, {
      method: "POST",
      headers: {
        "Authorization": "Token "+token
      },
    })
    .then(()=>{
      history.push('/');
    })
  }
 
  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <p> Admin Panel</p>
        <hr />
      </div>
      <div className="center">
        <ul>
        <p className="title">SHOP</p>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-grip icon"></i>
              <span className='text-start'>Shop</span>
            </li>
          </Link>
          <p className="title">MAIN</p>
          <Link to="/admin" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-grip icon"></i>
              <span className='text-start'>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/admin-customers" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-user-group icon"></i>
              <span className='text-start ms-3'>Customers</span>
            </li>
          </Link>
          <Link to="/admin-books" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-book icon me-3"></i>
              <span className='ms-1'>Books</span>
            </li>
          </Link>
          <Link to="/admin-electronics" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-headphones icon me-3"></i>
              <span className='ms-1'>Electronics</span>
            </li>
          </Link>
          <Link to="/admin-stationary" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-palette icon me-3"></i>
              <span className='ms-1'>Stationary</span>
            </li>
          </Link>
          <Link to="/admin-categories" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-bookmark icon me-1"></i>
              <span className='text-start'>Categories</span>
            </li>
          </Link>
          <Link to="/admin-orders" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-sort icon me-1 p-1 px-1"></i>
              <span className='text-start'>Orders</span>
            </li>
          </Link>
          <Link to="/admin-authors" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-pen-nib icon"></i>
              <span className='text-start'>Authors</span>
            </li>
          </Link>
          <Link to="/admin-publishers" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-bullhorn icon"></i>
              <span className='text-start'>Publishers</span>
            </li>
          </Link>

          <p className="title">USEFUL</p>
          <Link to="/admin-logo" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start'>
              <i className="fa-solid fa-n icon me-2 p-1"></i>
              <span className='text-start'>Logo</span>
            </li>
          </Link>
          <Link to="/admin-banner" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start' >
              <i className="fa-regular fa-images icon"></i>
              <span className='text-start'>Banners</span>
            </li>
          </Link>

          <p className="title">ADMIN</p>
          {/* view admin profile */}
          <li >

            {/* <!-- Button trigger modal --> */}
            <button
              type="button"
              className=" btn btn-outline-none d-flex justify-content-start ps-0"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <i className="fa-solid fa-user icon me-2"></i>
            <span className='text-start'>Profile</span>
            </button>

            {/* <!-- Modal --> */}
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Admin Profile
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>Phone No: {user?.phone_number}</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {/* Make a new admin */}
          <Link to="/make-admin" style={{ textDecoration: 'none' }}>
            <li className='d-flex justify-content-start' >
              <i className="fa-solid fa-add icon"></i>
              <span className='text-start'>Admin</span>
            </li>
          </Link>
          {/* logout */}
          <li className='d-flex justify-content-start'>
            <i className="fa-solid fa-right-from-bracket icon"></i>
            <span className='text-start' onClick={logout}>Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
