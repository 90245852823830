import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation} from 'react-router-dom';
import Footer from '../common/footer/Footer';
import Header from '../common/header/Header';
import MegaMenu from '../common/MegaMenu/MegaMenu';
import { url } from '../../App';

export default function Login() {
  const {register, handleSubmit, reset, formState: {errors}} = useForm();

  //location redirect
  const location = useLocation();
  const history = useHistory();
  const redirect_uri = location.state?.from || '/';
  
  //onSubmit function
  const onSubmit = data => {
    fetch(`${url}/login/`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    })
    .then(res => {
      if (!res.ok) throw res;
      else return res.json();
    })
    .then(result => {
      if(result.errors){
        alert(result.errors);
      }
      else{
        localStorage.setItem('token', result.token.access);
        localStorage.setItem('user', JSON.stringify((data)));
        reset();
        history.push(redirect_uri);
      }
    })
  }

  
  return (
    <>
    <Header></Header>
    <MegaMenu></MegaMenu>
    <div className="container p-3 mb-5 row mx-auto">
      <div className="col-sm-12 col-md-12 col-lg-8 mt-3 mx-auto ">
        <p className="text-center h1 fw-bold mb-4 mx-1  mt-4">সাইন ইন</p>
        <form className="container mx-auto w-75 border rounded p-4 border-0 shadow" onSubmit={handleSubmit(onSubmit)} >

          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">ফোন নাম্বার:</label>
              <input
                  {...register("phone_number", {required: true})}
                  type="text"
                  pattern="[0-9]*"
                  placeholder='ফোন নাম্বার লিখুন'                  
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 custom-border-outline rounded fs-6 fw-small"
              />
              {errors.phone_number?.type === 'required' && <p role="alert" className="text-danger "><small>ফোন নাম্বার অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className="row mb-3 w-100 mx-auto">
              <label className="col-sm-12 col-md-12 col-lg-3 py-2 ">পাসওয়ার্ড:</label>
              <input
                  {...register("password", {required: true})}
                  type="password"
                  placeholder='পাসওয়ার্ড লিখুন'                  
                  className=" p-1 col-sm-12 col-md-12 col-lg-9 custom-border-outline rounded fs-6 fw-small"
              />
              {errors.password?.type === 'required' && <p role="alert" className="text-danger "><small>পাসওয়ার্ড অত্যাবশ্যকিয়*</small></p>}
          </div>
          <div className='d-flex justify-content-center mx-4 mb-3 mb-lg-4'>
              <input
                type="submit"
                value="সাইন ইন"
                className="btn border-bg text-white rounded "
              />
          </div>
            <p className="text-center heading"><Link to="/forget-password" className='heading fw-bold'>আপনার পাসওয়ার্ড ভুলে গেছেন ?</Link>
            </p>
            <p className="text-center heading fw-bold">আপনি কি এখানে নতুন?<Link   to={{ pathname: "/register" }} className='heading fw-bold'> একাউন্ট তৈরি করতে ক্লিক করুন!</Link></p>
        </form>
      </div>
    </div>
    <Footer></Footer>
    </>
  );
}
