import {useEffect,useState} from 'react';
import {url} from '../../App';


export default function useCompetitiveExam() {
    const [competitiveBooks, setCompetitiveBooks] = useState([]);

    useEffect(() => {
        fetch(`${url}/category-book/কম্পিটিটিভ-এক্সাম`)
       .then(res => res.json())
       .then(result => {
        setCompetitiveBooks(result);
       });
    }, []);

return [ competitiveBooks ];

}